import React from 'react'
import cN from 'classnames'

import FilesLabels from './filesLabel'

// import '../style.scss'


export default function SectionHeader (props) {
    const {
        getRootProps, getInputProps, 
        isDragActive, isDragAccept, isDragReject,
        maxFiles, maxSize, acceptedFormats, fileOptions
    } = props

    return <section {...getRootProps()}
        className={cN('Dropzone', {
            active: isDragActive,
            valid: isDragAccept,
            invalid: isDragReject
        })}>
        <input {...getInputProps()} />

        <i className='onek-drag-drop big mb-3'></i>

        <span>
            Drag files here or click to select (filenames must be unique)
        </span>

        {fileOptions ? <>
            <span>Accepted file types:</span>
            <FilesLabels fileOptions={fileOptions} />
        </>
            : <>
                <span>
                    Max size per file: {maxSize}MB
                    { maxFiles && <span>{' | '} Max files: {maxFiles}</span> }
                </span>
                <span>
                    Max size per file: {maxSize}MB
                    { maxFiles && <span>{' | '} Max files: {maxFiles}</span> }
                </span>
                <span>
                    Accepted file types: { acceptedFormats.join(', ') }
                </span>
            </>
        }
    </section>
}