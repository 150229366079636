/**
 * Localization Portal
 * Localization Portal
 *
 * The version of the OpenAPI document: v1
 * Contact: rlucato@keywordsstudios.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The LocalizationRequestHistory model module.
 * @module model/LocalizationRequestHistory
 * @version v1
 */
class LocalizationRequestHistory {
    /**
     * Constructs a new <code>LocalizationRequestHistory</code>.
     * @alias module:model/LocalizationRequestHistory
     * @param id {Number} 
     * @param fileName {String} 
     * @param locreqId {Number} 
     * @param projectId {Number} 
     * @param service {String} 
     * @param fullLanguageName {String} 
     * @param draftCreatedAt {Date} 
     * @param startedAt {Date} 
     * @param startedBy {String} 
     * @param translationAssigned {Object} 
     * @param translationNotResponded {Object} 
     * @param translationAccepted {Object} 
     * @param translationRejected {Object} 
     * @param translationDelivered {Date} 
     * @param reviewAssigned {Object} 
     * @param reviewNotResponded {Object} 
     * @param reviewAccepted {Object} 
     * @param reviewRejected {Object} 
     * @param reviewDelivered {Date} 
     * @param invoicedAt {Date} 
     */
    constructor(id, fileName, locreqId, projectId, service, fullLanguageName, draftCreatedAt, startedAt, startedBy, translationAssigned, translationNotResponded, translationAccepted, translationRejected, translationDelivered, reviewAssigned, reviewNotResponded, reviewAccepted, reviewRejected, reviewDelivered, invoicedAt) { 
        
        LocalizationRequestHistory.initialize(this, id, fileName, locreqId, projectId, service, fullLanguageName, draftCreatedAt, startedAt, startedBy, translationAssigned, translationNotResponded, translationAccepted, translationRejected, translationDelivered, reviewAssigned, reviewNotResponded, reviewAccepted, reviewRejected, reviewDelivered, invoicedAt);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, id, fileName, locreqId, projectId, service, fullLanguageName, draftCreatedAt, startedAt, startedBy, translationAssigned, translationNotResponded, translationAccepted, translationRejected, translationDelivered, reviewAssigned, reviewNotResponded, reviewAccepted, reviewRejected, reviewDelivered, invoicedAt) { 
        obj['id'] = id;
        obj['file_name'] = fileName;
        obj['locreq_id'] = locreqId;
        obj['project_id'] = projectId;
        obj['service'] = service;
        obj['full_language_name'] = fullLanguageName;
        obj['draft_created_at'] = draftCreatedAt;
        obj['started_at'] = startedAt;
        obj['started_by'] = startedBy;
        obj['translation_assigned'] = translationAssigned;
        obj['translation_not_responded'] = translationNotResponded;
        obj['translation_accepted'] = translationAccepted;
        obj['translation_rejected'] = translationRejected;
        obj['translation_delivered'] = translationDelivered;
        obj['review_assigned'] = reviewAssigned;
        obj['review_not_responded'] = reviewNotResponded;
        obj['review_accepted'] = reviewAccepted;
        obj['review_rejected'] = reviewRejected;
        obj['review_delivered'] = reviewDelivered;
        obj['invoiced_at'] = invoicedAt;
    }

    /**
     * Constructs a <code>LocalizationRequestHistory</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/LocalizationRequestHistory} obj Optional instance to populate.
     * @return {module:model/LocalizationRequestHistory} The populated <code>LocalizationRequestHistory</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new LocalizationRequestHistory();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('file_name')) {
                obj['file_name'] = ApiClient.convertToType(data['file_name'], 'String');
            }
            if (data.hasOwnProperty('locreq_id')) {
                obj['locreq_id'] = ApiClient.convertToType(data['locreq_id'], 'Number');
            }
            if (data.hasOwnProperty('project_id')) {
                obj['project_id'] = ApiClient.convertToType(data['project_id'], 'Number');
            }
            if (data.hasOwnProperty('service')) {
                obj['service'] = ApiClient.convertToType(data['service'], 'String');
            }
            if (data.hasOwnProperty('full_language_name')) {
                obj['full_language_name'] = ApiClient.convertToType(data['full_language_name'], 'String');
            }
            if (data.hasOwnProperty('draft_created_at')) {
                obj['draft_created_at'] = ApiClient.convertToType(data['draft_created_at'], 'Date');
            }
            if (data.hasOwnProperty('started_at')) {
                obj['started_at'] = ApiClient.convertToType(data['started_at'], 'Date');
            }
            if (data.hasOwnProperty('started_by')) {
                obj['started_by'] = ApiClient.convertToType(data['started_by'], 'String');
            }
            if (data.hasOwnProperty('translation_assigned')) {
                obj['translation_assigned'] = ApiClient.convertToType(data['translation_assigned'], Object);
            }
            if (data.hasOwnProperty('translation_not_responded')) {
                obj['translation_not_responded'] = ApiClient.convertToType(data['translation_not_responded'], Object);
            }
            if (data.hasOwnProperty('translation_accepted')) {
                obj['translation_accepted'] = ApiClient.convertToType(data['translation_accepted'], Object);
            }
            if (data.hasOwnProperty('translation_rejected')) {
                obj['translation_rejected'] = ApiClient.convertToType(data['translation_rejected'], Object);
            }
            if (data.hasOwnProperty('translation_delivered')) {
                obj['translation_delivered'] = ApiClient.convertToType(data['translation_delivered'], 'Date');
            }
            if (data.hasOwnProperty('review_assigned')) {
                obj['review_assigned'] = ApiClient.convertToType(data['review_assigned'], Object);
            }
            if (data.hasOwnProperty('review_not_responded')) {
                obj['review_not_responded'] = ApiClient.convertToType(data['review_not_responded'], Object);
            }
            if (data.hasOwnProperty('review_accepted')) {
                obj['review_accepted'] = ApiClient.convertToType(data['review_accepted'], Object);
            }
            if (data.hasOwnProperty('review_rejected')) {
                obj['review_rejected'] = ApiClient.convertToType(data['review_rejected'], Object);
            }
            if (data.hasOwnProperty('review_delivered')) {
                obj['review_delivered'] = ApiClient.convertToType(data['review_delivered'], 'Date');
            }
            if (data.hasOwnProperty('invoiced_at')) {
                obj['invoiced_at'] = ApiClient.convertToType(data['invoiced_at'], 'Date');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
LocalizationRequestHistory.prototype['id'] = undefined;

/**
 * @member {String} file_name
 */
LocalizationRequestHistory.prototype['file_name'] = undefined;

/**
 * @member {Number} locreq_id
 */
LocalizationRequestHistory.prototype['locreq_id'] = undefined;

/**
 * @member {Number} project_id
 */
LocalizationRequestHistory.prototype['project_id'] = undefined;

/**
 * @member {String} service
 */
LocalizationRequestHistory.prototype['service'] = undefined;

/**
 * @member {String} full_language_name
 */
LocalizationRequestHistory.prototype['full_language_name'] = undefined;

/**
 * @member {Date} draft_created_at
 */
LocalizationRequestHistory.prototype['draft_created_at'] = undefined;

/**
 * @member {Date} started_at
 */
LocalizationRequestHistory.prototype['started_at'] = undefined;

/**
 * @member {String} started_by
 */
LocalizationRequestHistory.prototype['started_by'] = undefined;

/**
 * @member {Object} translation_assigned
 */
LocalizationRequestHistory.prototype['translation_assigned'] = undefined;

/**
 * @member {Object} translation_not_responded
 */
LocalizationRequestHistory.prototype['translation_not_responded'] = undefined;

/**
 * @member {Object} translation_accepted
 */
LocalizationRequestHistory.prototype['translation_accepted'] = undefined;

/**
 * @member {Object} translation_rejected
 */
LocalizationRequestHistory.prototype['translation_rejected'] = undefined;

/**
 * @member {Date} translation_delivered
 */
LocalizationRequestHistory.prototype['translation_delivered'] = undefined;

/**
 * @member {Object} review_assigned
 */
LocalizationRequestHistory.prototype['review_assigned'] = undefined;

/**
 * @member {Object} review_not_responded
 */
LocalizationRequestHistory.prototype['review_not_responded'] = undefined;

/**
 * @member {Object} review_accepted
 */
LocalizationRequestHistory.prototype['review_accepted'] = undefined;

/**
 * @member {Object} review_rejected
 */
LocalizationRequestHistory.prototype['review_rejected'] = undefined;

/**
 * @member {Date} review_delivered
 */
LocalizationRequestHistory.prototype['review_delivered'] = undefined;

/**
 * @member {Date} invoiced_at
 */
LocalizationRequestHistory.prototype['invoiced_at'] = undefined;






export default LocalizationRequestHistory;

