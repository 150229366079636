import { toast } from 'react-toastify';
import {createMenuItems, isProjectPage, isClientPage, isArchivedPage} from './utils'
import {checkIfThereAreActiveServices} from '../../../../utils'

// generate logic for Superuser routes
export const superuserRoutes = ({
    toggleClientNew, toggleProjectNew, toggleRequestNew, currentProject
}, callback) => (pathComponents) => {

    // /project/{projectId}
    if (isProjectPage(pathComponents)) {
        const item = createMenuItems[2]
        item.isActive = checkIfThereAreActiveServices(currentProject) 
        item.onClick = () => toggleRequestNew(true)

        callback([item])
        return
    }

    // /client/{clientId}
    if (isClientPage(pathComponents)) {
        if (isArchivedPage(pathComponents)) {
            callback([])
            return
        }

        const clientId = pathComponents[1]
        const item = createMenuItems[1]
        item.isActive = true
        item.onClick = () => {
            toggleProjectNew({active: true, clientId})
            .then((activeUsers) => {
                if (!activeUsers) {
                    toast.error(`Warning: there is no KWS PM enabled for this client, it is not possible to create a project`)
                }
            })
        }

        callback([item])
        return
    }

    // new client
    const item = createMenuItems[0]
    item.isActive = true
    item.onClick = () => toggleClientNew(true)

    callback([item])
}
