import React, { useContext, useEffect, useState } from 'react'

import { Button, Table, OverlayTrigger, Tooltip } from 'react-bootstrap'

import { GlobalContext } from '../../context/GlobalState'

import Dialog from 'react-bootstrap-dialog'
import { toast } from 'react-toastify';

import EntityNotFound from '../entity-not-found/EntityNotFound'
import MemberForm from './MemberForm'
import Loading from '../loading/Loading'

const Members = (props) => {
  const { getProjectMembers, deleteProjectMember, user } = useContext(GlobalContext)
  const [members, setMembers] = useState([])
  const [loadingMembers, setLoadingMembers] = useState(true)
  const [showForm, setShowForm] = useState(false)
  const [dialog, setDialog] = useState(null)

  useEffect(() => {
    getProjectMembers(props.project.id)
      .then((data) => {
        setMembers(parseMembers(data.results))
        setLoadingMembers(false)
      })
      .catch(() => setLoadingMembers(false))
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (props.activeTab !== 'members' && showForm) {
      // Hide form
      onFormHide()
    }
    // eslint-disable-next-line
  }, [props.activeTab])

  const sortMembers = (members) => {
    return members.sort((a, b) => {
      return a.name.toLowerCase() > b.name.toLowerCase()
        ? 1 : -1
    })
  }

  const parseMembers = (members) => {
    if (members && members.length) {
      return members
        .filter(c => c.user_type === 'client-pm')
        .map(parseMember)
    }

    return sortMembers(members)
  }

  const parseMember = (m) => {
    return {
      ...m,
      name: m.first_name || m.last_name
        ? `${m.first_name} ${m.last_name}`
        : m.username
    }
  }

  const onDeleteMember = (e, member) => {
    e.preventDefault()

    dialog.show({
      title: `Remove ${member.name}`,
      body: `Do you really want to remove this member from the project?`,
      actions: [
        Dialog.Action(
          'CANCEL',
          () => {},
          'btn-default'
        ),
        Dialog.Action(
          'YES, REMOVE',
          () => {
            deleteProjectMember(props.project.id, member.id)
              .then(() => {
                toast.success(`${member.name} has been removed`)
                setMembers(members.filter((m) => {
                  return m.id !== member.id
                }))
              })
          },
          'btn-danger'
        )
      ],
      bsSize: 'small',
    })
  }

  const onFormHide = (member) => {
    setShowForm(false)

    if (member) {
      const parsedMember = parseMember(member)
      toast.success(`${parsedMember.name} has been added`)
      setMembers(sortMembers([
        ...members,
        parsedMember
      ]))
    }
  }

  return (
    <>{ showForm
      ? <MemberForm
        clientId={props.clientId}
        project={props.project}
        users={members}
        userType='client-pm'
        onHide={onFormHide} />
      : <>
        <Dialog ref={ (el) => setDialog(el) }/>
        <div className="text-right">
          <Button id="new-member-btn"
            variant="secondary"
            onClick={() => { setShowForm(true) }}>
            <i className="onek-new" />{' '}
            ADD TEAM MEMBER
          </Button>
        </div>
        { loadingMembers
          ? <Loading />
          : !members.length
          ? <EntityNotFound entityName="Members" />
          : <Table striped className="small mt-4 layout-fixed">
            <thead>
              <tr>
                <th colSpan="2">
                  TEAM MEMBERS
                </th>
                <th className="one-btn"></th>
              </tr>
            </thead>
            <tbody>
            { members.map((member) => {
              return (
                <tr key={member.id}>
                  <td className="w-100">
                    { member.name }
                  </td>
                  <td>{ member.email }</td>
                  <td>
                  { user.username !== member.username &&
                    <OverlayTrigger placement="top"
                      overlay={<Tooltip>Delete</Tooltip>}>
                      <button className="icon-only delete-member-btn"
                        onClick={(e) => onDeleteMember(e, member)}>
                        <i className="onek-delete" />
                      </button>
                    </OverlayTrigger>
                  }
                  </td>
                </tr>
              )
            })}
            </tbody>
          </Table>
        }
      </>
    }</>
  )
}

export default Members
