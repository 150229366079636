import { createContext } from 'react'
import { generateGroups } from '../utils'

let termsNotAccepted = false
try {
  termsNotAccepted = !localStorage.getItem('lp-tos-acceptance')
} catch (e) {
  termsNotAccepted = true
}

export const initialState = {
  // app state
  projects: [],
  requests: [],
  clients: [],
  memoqProjects: [],
  bpsProjects: [],
  currentClient: false,
  loadingClient: false,
  currentProject: false,
  loadingProject: false,
  currentRequest: false, // maybe this isn't be necessary...
  
  // opts
  authenticating: true,
  termsNotAccepted,
  lettersGroups: generateGroups(50),

  // UI Modal States
  requestNewIsOpen: false,
  projectNewIsOpen: false,
  clientNewIsOpen: false,
  projectEditIsOpen: false,
  projectToEdit: null,
  clientEditIsOpen: false,
  clientToEdit: null,
  userFormIsOpen: false,

  // States
  activeProjectUsersCount: 0,
  projectIsSyncing: false,
}


export default createContext(initialState)