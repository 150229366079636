import React, { useContext, useEffect, useState } from 'react'

import { Table } from 'react-bootstrap'

import { GlobalContext } from '../../context/GlobalState'

import { toast } from 'react-toastify';

import EntityNotFound from '../entity-not-found/EntityNotFound'
import MemberForm from './MemberForm'
import Loading from '../loading/Loading'
import { parseUser } from '../../utils'

const ProjectContacts = (props) => {
  const { getProjectMembers} = useContext(GlobalContext)
  const [contacts, setContacts] = useState([])
  const [loadingContacts, setLoadingContacts] = useState(true)
  const [showForm, setShowForm] = useState(false)

  useEffect(() => {
    getProjectMembers(props.project.id)
      .then((data) => {
        setContacts(parseContacts(data.results))
        setLoadingContacts(false)
      })
      .catch(() => setLoadingContacts(false))
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (props.activeTab !== 'contacts' && showForm) {
      // Hide form
      onFormHide()
    }
    // eslint-disable-next-line
  }, [props.activeTab])

  const sortContacts = (contacts) => {
    return contacts.sort((a, b) => {
      return a.name.toLowerCase() > b.name.toLowerCase()
        ? 1 : -1
    })
  }

  const parseContacts = (contacts) => {
    if (contacts && contacts.length) {
      return contacts
        .filter(c => c.user_type === 'kws-pm')
        .map(parseUser)
    }

    return sortContacts(contacts)
  }

  const onFormHide = (contact) => {
    setShowForm(false)

    if (contact) {
      const parsedContact = parseUser(contact)
      toast.success(`${parsedContact.name} has been added`)
      setContacts(sortContacts([
        ...contacts,
        parsedContact
      ]))
    }
  }

  return (
    <>{ showForm
      ? <MemberForm
        clientId={props.clientId}
        project={props.project}
        users={contacts}
        userType='kws-pm'
        onHide={onFormHide} />
      : <>
        { loadingContacts
          ? <Loading />
          : !contacts.length
            ? <EntityNotFound entityName="KWS PMs" />
            : <Table striped className="small mt-4 layout-fixed">
              <thead>
                <tr>
                  <th colSpan="2">
                    KWS PMs
                  </th>
                </tr>
              </thead>
              <tbody>
                { contacts.map((contact) => {
                  return (
                    <tr key={contact.id}>
                      <td>
                        { contact.name }
                      </td>
                      <td className="w-100">{ contact.email }</td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
        }
      </>
    }</>
  )
}

export default ProjectContacts
