import React, { useContext, useEffect, useState } from 'react'

import { Link, Switch, Route, useParams, useHistory, useLocation } from 'react-router-dom'

import { Container, Row, Col, Button, Image } from 'react-bootstrap'
import { toast } from 'react-toastify';

import { GlobalContext } from '../../context/GlobalState'
import cN from 'classnames'

import ProjectsTable from '../projects-table/ProjectsTable'
import NewProject from '../new-project/NewProject'
import ClientSettings from '../client-settings/ClientSettings'
import ClientStatistics from '../client-statistics/ClientStatistics'


const Dashboard = () => {
  const { id } = useParams()
  const history = useHistory()
  const location = useLocation()
  const [newProjectIsActive, setNewProjectIsActive] = useState(true)
  const isStatsPage = location.pathname.endsWith('stats')
  const { 
    user,
    projects, projectsCount, getProjects, projectsNotFound, 
    getClients, 
    currentClient,
    clientToEdit, toggleClientEdit, fetchClientActiveUsers,
    projectNewIsOpen, toggleProjectNew
  } = useContext(GlobalContext)


  //
  useEffect(() => {
    if (!id && user?.isKWSPM) {
      history.replace('/dashboard')
    } else if (!projects.length && !projectsNotFound) {
      getProjects(id || user.client.id)
    }
    // eslint-disable-next-line
  }, [])
  
  useEffect(() => {
    if (!currentClient?.id) {
      return
    }
    
    fetchClientActiveUsers(currentClient.id)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient])
  //


  //
  const onProjectModalHide = (project, archived) => {
    toggleProjectNew({active: false})

    if (archived || project) {
      if (user && user.isKWSPM) {
        getClients()
      }
    }
  }

  const onClientModalHide = (client) => {
    toggleClientEdit(false)

    if (client) {
      toast.success(`Client info successfully updated`)
    }
  }

  const handleNewProject = () => {
    setNewProjectIsActive(false)
    toggleProjectNew({active: true, clientId: currentClient.id})
    .then((activeUsers) => {
      if (!activeUsers) {
          toast.error(`Warning: there is no KWS PM enabled for this client, it is not possible to create a project`)
      }
    })
    .finally(() => {
      setNewProjectIsActive(true)
    })
  }
  //

  return (
    currentClient &&
    <Container fluid className="Dashboard">
      <Row className="hero">
        <div className="banner"
          style={ currentClient.banner ? {backgroundImage: `url('${currentClient.banner}')`}: null }>
        </div>
        <Col xs className="d-flex flex-column">
          <div className={cN('section-header', {
              'has-logo': currentClient.logo
            })}>
          { currentClient.logo &&
            <Image src={ currentClient.logo }
              alt={ currentClient.name } className="logo" fluid/>
          }
            <h1 className="ellipsis">
              { currentClient.name } DASHBOARD
            </h1>
            <div xs="auto" className="section-subheader">
              <span>TOTAL PROJECTS: { projectsCount || 0 }</span>
              <span>ONGOING PROJECTS: { currentClient.ongoing_count || 0 }</span>
            </div>
          </div>
          <Row>
            <Col className="d-flex justify-content-end align-items-end">
              { isStatsPage
                ? <Button id="view-projects-btn"
                  as={Link}
                  to={ id ? `/client/${id}` : '/'}
                  variant="primary">
                  VIEW PROJECTS
                </Button>
                : <Button id="view-stats-btn"
                as={Link}
                to={ id ? `/client/${id}/stats` : '/stats'}
                variant="primary">
                <i className="onek-statistics" />{' '}
                VIEW STATISTICS
                </Button>
              }
              <Button id="new-project-btn"
                variant="secondary"
                disabled={!newProjectIsActive}
                onClick={handleNewProject}>
                <i className="onek-new" />{' '}
                NEW PROJECT
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
            <Switch>
              <Route  exact path={[
                "/",
                "/client/:id"
              ]}>
                <ProjectsTable clientId={currentClient.id} />
              </Route>
              <Route exact path={[
                "/stats",
                "/client/:id/stats"
              ]}>
                <ClientStatistics />
              </Route>
            </Switch>
        </Col>
      </Row>
      <NewProject
        id="new-project-modal"
        show={projectNewIsOpen}
        clientId={currentClient.id}
        onHide={onProjectModalHide} />
      <ClientSettings show={!!clientToEdit}
        client={currentClient}
        onHide={ onClientModalHide } />
    </Container>
  )
}

export default Dashboard
