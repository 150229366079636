import React, { useState, useContext, useEffect } from 'react'

import { Modal, Button, Toast, ProgressBar } from 'react-bootstrap'
import Dialog from 'react-bootstrap-dialog'

import { GlobalContext } from '../../context/GlobalState'

import { services, source_languages, target_languages } from '../../temp_lists'

import moment from 'moment'

const StartRequest = (props) => {
  const checkStatusInterval = 5000
  const timeout = 30
  const timeoutClose = 30
  const { updateRequestStatus, getRequestByID, updateRequest } = useContext(GlobalContext)
  const [errorMsg, setErrorMsg] = useState(null)
  const [showError, setShowError] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [waitingForOngoing, setWaitingForOngoing] = useState(null)
  const [request, setRequest] = useState(null)
  const [dialog, setDialog] = useState(null)
  const [timeoutForClose, setTimeoutForClose] = useState(null)

  useEffect(() => {
    setRequest(props.request)

    return () => {
      setSubmitting(false)
      setWaitingForOngoing(null)
      setShowError(false)
      setErrorMsg(null)
    }
  // eslint-disable-next-line
  }, [props.request])

  const onError = (err, req) => {
    setSubmitting(false)
    setWaitingForOngoing(null)

    // Check if some target language is missing after
    // changing the LR status
    if (req &&
      request.target_languages.some((l) => {
        return req.target_languages.indexOf(l) < 0
      })) {
      setRequest(req)
      if (req.target_languages &&
        req.target_languages.length) {
        dialog.show({
          title: 'WARNING',
          body: `Some target languages were automatically removed from this request, since they are not present anymore in the pricelist. Are you sure you want to start this request anyway?`,
          actions: [
            Dialog.Action(
              'NO',
              () => {
                props.onHide(req)
              },
              'btn-default'
            ),
            Dialog.Action(
              'YES',
              () => {
                onSubmit()
              },
              'btn-primary'
            )
          ],
          bsSize: 'small',
        })
      } else {
        props.onHide(req)
      }
    } else {
      try {
        let error = JSON.parse(err.response.text)
        setErrorMsg(error.detail || err.response.text)
        setShowError(!!(error.detail || err.response.text))
      } catch (e) {
        setErrorMsg(err.response.text)
        setShowError(!!err.response.text)
      }
    }
  }

  const onSubmit = () => {
    setSubmitting(true)
    setShowError(false)

    // If uploading > 30, close the modal
    const t = setTimeout(() => {
      props.onHide()
    }, timeoutClose * 1000)
    setTimeoutForClose(t)

    updateRequestStatus(props.projectId, request.id, request.state, 'ongoing')
      .then((req) => {
        if (req && req.state === 'ongoing') {
          setSubmitting(false)
          clearInterval(timeoutForClose)
          props.onHide(req)
        } else {
          setWaitingForOngoing(moment())
        }
      })
      .catch(onError)
  }

  const checkStatus = () =>  {
    if (waitingForOngoing) {
      getRequestByID(request.id, props.projectId)
        .then(req => {
          updateRequest(req, props.projectId)
          if (req) {
            if (req.state_meta && req.state_meta.errors) {
              onError({
                response: {
                  text: req.state_meta.errors
                }
              }, req)
            } else if (req.state === 'ongoing' ||
              moment().diff(waitingForOngoing, 'seconds') > timeout) {
                
              clearInterval(timeoutForClose)
              props.onHide(req)
            }
          }
        })
        .catch(onError)
    }
  }

  useEffect(() => {
    let interval = null
    if (waitingForOngoing) {
      interval = setInterval(checkStatus, checkStatusInterval)
    }

    return () => clearInterval(interval)
  // eslint-disable-next-line
  }, [waitingForOngoing])

  return (request &&
    <>
      <Dialog ref={ (el) => setDialog(el) }/>
      <Modal show={props.show} onHide={props.onHide}
        centered scrollable size={'md'}>
        <Modal.Header closeButton={true}>
          <Modal.Title>Confirm start of new request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Toast show={showError}
            onClose={() => setShowError(false)}>
            <Toast.Header>
              <strong className="mr-auto">Request couldn't be started</strong>
            </Toast.Header>
            <Toast.Body>{ errorMsg }</Toast.Body>
          </Toast>
          <h1 className="text-center">{ request.number }</h1>
          {
            waitingForOngoing
              ? <ProgressBar animated={true}
              style={{ margin: '3rem 1rem 2rem' }}
              variant="success"
              now={100}
              label="Uploading request..." />
              : <div className="uppercase">
              <p>
                <i className="onek-service big"></i>
                Service:{' '}
                { services.find((service) => {
                  return service.code ===  request.service
                }).name }
              </p>
              <p>
                <i className="onek-source big"></i>
                Source language:{' '}
                <span className="transform-none">
                  { source_languages.find((lang) => {
                    return lang.code ===  request.source_language
                  }).name }
                </span>
              </p>
              <p>
                <i className="onek-target big"></i>
                Target languages:</p>
              <ul>
                { !!request.target_languages && request.target_languages.map((langCode) => {
                    return <li key={langCode}>
                      { target_languages.find((lang) => {
                        return lang.code ===  langCode
                      }).name }
                    </li>
                  })
                }
              </ul>
              { !!request.files &&
                <p>
                  <i className="onek-files big"></i>
                  Files uploaded: { request.files.length }
                </p>
              }
            </div>
          }
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button id="submit-btn"
            variant="primary"
            type="submit"
            onClick={onSubmit}
            disabled={submitting}>
            { submitting ?
              waitingForOngoing
                ? 'Uploading...'
                : 'Processing...'
              : 'CONFIRM START'
            }
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default StartRequest
