import React, { useReducer } from 'react'
import AppReducer from './AppReducer'
import GlobalContext, {initialState} from './initialState'

import { APIClient, AuthAPI, UsersAPI, ProjectsAPI, ClientsAPI, DashboardAPI, BpsAPI, MemoqAPI, InternalAPI } from './ApiWrapper'

import { generateGroups, parseAssociatedClients, uploadImage } from '../utils'
import { target_languages } from '../temp_lists'

import {getArchivedProjects, getArchivedRequests, getArchivedProject, projectsArchiveCreate} from './chunks/archived'
import {getLanguagesHistory} from './chunks/history'
import {checkForUnassignedFiles} from './chunks/projectUtils'
import {getResources, updateResource} from './chunks/resources'
import {uploadProfilePicture, getUserPictureUrl, getUser, updateUser} from './chunks/users'

export {GlobalContext}

const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState)

  // Actions
  function acceptTerms () {
    localStorage.setItem('lp-tos-acceptance', new Date())

    dispatch({
      type: 'ACCEPT_TERMS'
    })
  }

  function authenticateUser (username, password) {
    return new Promise((resolve, reject) => {
      AuthAPI.authTokenCreate({
        username,
        password
      }, (err, data) => {
        if (!err) {
          localStorage.setItem('lp-sess', data.access)
          localStorage.setItem('lp-sess-refresh', data.refresh)

          setTokens(data)

          resolve(data)
        } else {
          dispatch({
            type: 'AUTH_FAILED'
          })

          reject(err)
        }
      })
    })
  }

  function logout (loggedOut) {
    localStorage.removeItem('lp-sess')
    localStorage.removeItem('lp-sess-refresh')

    dispatch({
      type: 'LOGOUT',
      payload: {
        loggedOut: loggedOut || false,
        state: initialState
      }
    })
  }

  function forgotPassword (username) {
    return new Promise((resolve, reject) => {
      AuthAPI.authPasswordResetCreate({
        username
      }, (err, data) => {
        if (err) {
          reject(err)
        } else {
          resolve(data)
        }
      })
    })
  }

  function resetPassword (uid, data) {
    return new Promise((resolve, reject) => {
      AuthAPI.authPasswordResetConfirmCreate(uid, data, (err, data) => {
        if (err) {
          reject(err)
        } else {
          resolve(data)
        }
      })
    })
  }

  function getTokens () {
    const access = localStorage.getItem('lp-sess')
    const refresh = localStorage.getItem('lp-sess-refresh')

    if (access && refresh) {
      const tokens = { access, refresh}
      setTokens(tokens)
      return tokens
    }

    return null
  }

  function setTokens (tokens) {
    APIClient.authentications.Bearer.apiKeyPrefix = 'Bearer'
    APIClient.authentications.Bearer.apiKey = tokens.access
    dispatch({
      type: 'SET_TOKENS',
      payload: tokens
    })
  }

  function updateAccessToken (token) {
    localStorage.setItem('lp-sess', token)
    APIClient.authentications.Bearer.apiKey = token
  }

  function refreshTokenPipe (err) {
    // Try to use refresh token to recover the session
    return new Promise((resolve, reject) => {
      if (err && err.response && err.response.text) {
        try {
          let parsedErrorMsg = JSON.parse(err.response.text)
          if (parsedErrorMsg.code === 'token_not_valid') {
            const tokens = state.tokens || getTokens()
            if (!tokens.refresh) {
              reject()
            } else {
              AuthAPI.authTokenRefreshCreate({
                refresh: tokens.refresh
              }, (err, data) => {
                if (err) {
                  dispatch({
                    type: 'AUTH_FAILED'
                  })
                  logout()
                  reject(err)
                } else {
                  updateAccessToken(data.access)

                  dispatch({
                    type: 'REFRESH_TOKEN',
                    payload: data
                  })
                  resolve(true)
                }
              })
            }
          } else {
            resolve()
          }
        } catch (e) {
          resolve()
        }
      } else {
        resolve()
      }
    })
  }

  function parseProject (project) {
    if (!project.ongoing_count) {
      project.ongoing_count = 0
    }
    if (!project.delivered_count) {
      project.delivered_count = 0
    }

    project.available_service_levels = []
    project.target_languages = []
    project.target_languages_per_service = {}

    if (project.services && project.services.length) {
      project.services.forEach((service) => {
        project.available_service_levels.push(service.service)

        if (service.target_languages) {
          project.target_languages_per_service[service.service] = target_languages
            .filter((l) => service.target_languages.indexOf(l.code) > -1)
            service.target_languages.forEach((lang) => {
              if (project.target_languages.indexOf(lang) < 0) {
                project.target_languages.push(lang)
              }
            })
        }
      })
    }

    return project
  }

  function getProjects (clientId, page, ordering, dontDispatch) {
    return new Promise((resolve, reject) => {
      ClientsAPI.clientsProjectsList(clientId, {
        page,
        ordering
      }, (err, data) => {
        refreshTokenPipe(err)
          .then((reAuthenticated) => {
            if (reAuthenticated) {
              getProjects(clientId, page, ordering)
                .then(resolve, reject)
            } else {
              if (err) {
                reject(err)
              } else {
                let hasData = data && data.results && data.results.length
                if (hasData) {
                  data.results.forEach(parseProject)
                }

                resolve(data)

                if (!dontDispatch) {
                  dispatch(hasData
                    ? {
                      type: 'SET_PROJECTS',
                      payload: data
                    }
                    : {
                      type: 'PROJECTS_NOT_FOUND'
                    })
                }
              }
            }
          })
      })
    })
  }

  function getProjectByID (id) {
    return new Promise((resolve, reject) => {
      setLoadingProject(true)
      ProjectsAPI.projectsRead(id, (err, data) => {
        refreshTokenPipe(err)
          .then((reAuthenticated) => {
            if (reAuthenticated) {
              getProjectByID(id)
                .then(resolve, reject)
            } else {
              setLoadingProject(false)
              if (err) {
                reject(err)
              } else {
                const project = parseProject(data)
                setCurrentProject(project)
                getClientByID(project.client)
                .finally(() => resolve(project))
              }
            }
          })
      })
    })
  }

  function createProject (clientId, newProject) {
    return new Promise((resolve, reject) => {
      ClientsAPI.clientsProjectsCreate(clientId, newProject, (err, data) => {
        refreshTokenPipe(err)
          .then((reAuthenticated) => {
            if (reAuthenticated) {
              createProject(clientId, newProject)
                .then(resolve, reject)
            } else {
              if (err) {
                reject(err)
              } else {
                resolve(parseProject(data))
              }
            }
          })
      })
    })
  }

  function addProject (project) {
    dispatch({
      type: 'ADD_PROJECT',
      payload: project
    })
  }

  function editProject (id, changes) {
    return new Promise((resolve, reject) => {
      ProjectsAPI.projectsUpdate(id, changes, (err, data, response) => {
        refreshTokenPipe(err)
          .then((reAuthenticated) => {
            if (reAuthenticated) {
              editProject(id, changes)
                .then(resolve, reject)
            } else {
              if (err) {
                reject(response)
              } else {
                resolve(parseProject(data))
              }
            }
          })
      })
    })
  }

  function updateProject (project) {
    dispatch({
      type: 'UPDATE_PROJECT',
      payload: project
    })
  }

  function deleteProject (id) {
    return new Promise((resolve, reject) => {
      ProjectsAPI.projectsDelete(id, (err, data) => {
        refreshTokenPipe(err)
          .then((reAuthenticated) => {
            if (reAuthenticated) {
              deleteProject(id)
                .then(resolve, reject)
            } else {
              if (err) {
                reject(err)
              } else {
                resolve(data)
              }
            }
          })
      })
    })
  }

  function removeProject (id) {
    dispatch({
      type: 'REMOVE_PROJECT',
      payload: id
    })
  }

  function resetProjectsList () {
    dispatch({
      type: 'RESET_PROJECTS_LIST'
    })
  }

  function getRequests (projectId, opts) {
    return new Promise((resolve, reject) => {
      ProjectsAPI.projectsLocreqList(projectId, opts || {}, (err, data) => {
        refreshTokenPipe(err)
          .then((reAuthenticated) => {
            if (reAuthenticated) {
              getRequests(projectId)
                .then(resolve, reject)
            } else {
              if (err) {
                reject(err)
              } else {
                dispatch({
                  type: 'SET_REQUESTS',
                  payload: data
                })

                resolve(data)
              }
            }
          })
      })
    })
  }

  function clearRequests () {
    dispatch({
      type: 'CLEAR_REQUESTS'
    })
  }

  function getProjectTemplates (projectId) {
    return new Promise((resolve, reject) => {
      ProjectsAPI.projectsTemplatesList(projectId, {
        // TODO: Support opts
      }, (err, data) => {
        refreshTokenPipe(err)
          .then((reAuthenticated) => {
            if (reAuthenticated) {
              getProjectTemplates(projectId)
                .then(resolve, reject)
            } else {
              if (err) {
                reject(err)
              } else {
                resolve(data)
              }
            }
          })
      })
    })
  }

  function createProjectTemplate (projectId, newTemplate) {
    return new Promise((resolve, reject) => {
      ProjectsAPI.projectsTemplatesCreate(projectId, newTemplate,
        (err, data) => {
        refreshTokenPipe(err)
          .then((reAuthenticated) => {
            if (reAuthenticated) {
              createProjectTemplate(projectId, newTemplate)
                .then(resolve, reject)
            } else {
              if (err) {
                reject(err)
              } else {
                resolve(data)
              }
            }
          })
      })
    })
  }

  function editProjectTemplate (projectId, templateId, changes) {
    return new Promise((resolve, reject) => {
      ProjectsAPI.projectsTemplatesUpdate(projectId, templateId,
        changes,
        (err, data) => {
        refreshTokenPipe(err)
          .then((reAuthenticated) => {
            if (reAuthenticated) {
              editProjectTemplate(projectId, templateId, changes)
                .then(resolve, reject)
            } else {
              if (err) {
                reject(err)
              } else {
                resolve(data)
              }
            }
          })
      })
    })
  }

  function deleteProjectTemplate (projectId, templateId) {
    return new Promise((resolve, reject) => {
      ProjectsAPI.projectsTemplatesDelete(projectId, templateId,
        (err, data) => {
        refreshTokenPipe(err)
          .then((reAuthenticated) => {
            if (reAuthenticated) {
              deleteProjectTemplate(projectId, templateId)
                .then(resolve, reject)
            } else {
              if (err) {
                reject(err)
              } else {
                resolve(data)
              }
            }
          })
      })
    })
  }

  function getUploadUrls (projectId, files) {
    return new Promise((resolve, reject) => {
      ProjectsAPI.projectsUploadsCreate(projectId, { files }, (err, data) => {
        refreshTokenPipe(err)
          .then((reAuthenticated) => {
            if (reAuthenticated) {
              getUploadUrls(projectId, files)
                .then(resolve, reject)
            } else {
              if (err) {
                reject(err)
              } else {
                resolve(data)
              }
            }
          })
      })
    })
  }

  function createRequest (projectId, newRequest) {
    return new Promise((resolve, reject) => {
      // Merge source and reference files into one single array
      const files = newRequest.files_source.map((file) => {
        return {
          ...file,
          file_type: 'source'
        }
      }).concat(newRequest.files_reference.map((file) => {
        return {
          ...file,
          file_type: 'reference'
        }
      }))

      const {
        files_source,
        files_reference,
        files_deleted, // Ignored in creation
        ...rest
      } = newRequest

      newRequest = {
        ...rest,
        files
      }

      ProjectsAPI.projectsLocreqCreate(projectId, newRequest, (err, data) => {
        refreshTokenPipe(err)
          .then((reAuthenticated) => {
            if (reAuthenticated) {
              createRequest(projectId, newRequest)
                .then(resolve, reject)
            } else {
              if (err) {
                reject(err)
              } else {
                resolve(data)
              }
            }
          })
      })
    })
  }

  function addRequest (request) {
    dispatch({
      type: 'ADD_REQUEST',
      payload: request
    })
  }

  function updateRequestStatus (projectId, requestId, state_old, state_new) {
    return new Promise((resolve, reject) => {
      ProjectsAPI.projectsLocreqStatusUpdate(requestId, projectId, {
        state_old,
        state_new
      }, (err, data) => {
        refreshTokenPipe(err)
          .then((reAuthenticated) => {
            if (reAuthenticated) {
              updateRequestStatus(projectId, requestId, state_old, state_new)
                .then(resolve, reject)
            } else {
              if (err) {
                reject(err)
              } else {
                dispatch({
                  type: 'UPDATE_REQUEST_STATUS',
                  payload: {
                    id: requestId,
                    newStatus: data.state
                  }
                })

                resolve(data)
              }
            }
          })
      })
    })
  }

  function getRequestByID (requestId, projectId) {
    return new Promise((resolve, reject) => {
      ProjectsAPI.projectsLocreqRead(requestId, projectId, (err, data) => {
        refreshTokenPipe(err)
          .then((reAuthenticated) => {
            if (reAuthenticated) {
              getRequestByID(requestId, projectId)
                .then(resolve, reject)
            } else {
              if (err) {
                reject(err)
              } else {
                resolve(data)
              }
            }
          })
      })
    })
  }

  function editRequest (projectId, requestId, data) {
    return new Promise((resolve, reject) => {
      let newFiles = []
      const types = ['source', 'reference']

      types.forEach((type) => {
        if (data['files_' + type] &&
          data['files_' + type].length) {
          newFiles = newFiles.concat(data['files_' + type]
            .filter((file) => !!file.fields)
            .map((file) => {
              return {
                ...file,
                file_type: type
              }
            }))
        }
      })

      const {
        files_source,
        files_reference,
        multilingual_updates,
        ...rest
      } = data

      const requestChanges = {
        ...rest,
        files_added: newFiles,
        multilingual_updates: Object
          .keys(multilingual_updates)
          .map((id) => {
            const update = { id }

            if (multilingual_updates[id]) {
              update.multilingual = multilingual_updates[id]
            }

            return update
          })
      }

      ProjectsAPI.projectsLocreqUpdate(
        requestId, projectId, requestChanges, (err, req) => {
        refreshTokenPipe(err)
          .then((reAuthenticated) => {
            if (reAuthenticated) {
              editRequest(projectId, requestId, data)
                .then(resolve, reject)
            } else {
              if (err) {
                reject(err)
              } else {
                resolve(req)
              }
            }
          })
      })
    })
  }

  function updateRequest (request, projectId) {
    dispatch({
      type: 'UPDATE_REQUEST',
      payload: {
        request,
        projectId
      }
    })
  }

  function deleteRequest (projectId, requestId) {
    return new Promise((resolve, reject) => {
      ProjectsAPI.projectsLocreqDelete(requestId, projectId, (err, data) => {
        refreshTokenPipe(err)
          .then((reAuthenticated) => {
            if (reAuthenticated) {
              deleteRequest(projectId, requestId)
                .then(resolve, reject)
            } else {
              if (err) {
                reject(err)
              } else {
                dispatch({
                  type: 'DELETE_REQUEST',
                  payload: requestId
                })

                resolve(data)
              }
            }
          })
      })
    })
  }

  // MARK: - Use this instead of project delete
  function internalProjectDelete(projectId) {
    return new Promise((resolve, reject) => {
      InternalAPI.internalCleanerCreate({
        deleteType: 'project',
        projectId
      }, (err, data, res) => {
        refreshTokenPipe(err)
          .then((reAuthenticated) => {
            if (reAuthenticated) {
              internalProjectDelete(projectId)
                .then(resolve, reject)
            } else {
              if (err) {
                reject(err)
              } else {
                resolve(data)
              }
            }
          })
      })
    })
  }

  function getMemoqProjects (clientId) {
    return new Promise((resolve, reject) => {
      ClientsAPI.clientsMemoqProjectsList(clientId, (err, data) => {
        refreshTokenPipe(err)
          .then((reAuthenticated) => {
            if (reAuthenticated) {
              getMemoqProjects(clientId)
                .then(resolve, reject)
            } else {
              if (err) {
                reject(err)
              } else {
                dispatch({
                  type: 'SET_MEMOQ_PROJECTS',
                  payload: data
                })

                resolve(data)
              }
            }
          })
      })
    })
  }

  function getBpsProjects (clientId) {
    return new Promise((resolve, reject) => {
      ClientsAPI.clientsBpsProjectsList(clientId, null, (err, data) => {
        refreshTokenPipe(err)
          .then((reAuthenticated) => {
            if (reAuthenticated) {
              getBpsProjects(clientId)
                .then(resolve, reject)
            } else {
              if (err) {
                reject(err)
              } else {
                resolve(data)
              }

              dispatch({
                type: 'SET_BPS_PROJECTS',
                payload: data
              })
            }
          })
      })
    })
  }

  function getBPSProjectLanguages (clientId, bpsProjectId) {
    return new Promise((resolve, reject) => {
      ClientsAPI.clientsBpsProjectsTargetLanguagesList(bpsProjectId, clientId, null, (err, data) => {
        refreshTokenPipe(err)
          .then((reAuthenticated) => {
            if (reAuthenticated) {
              getBPSProjectLanguages(clientId, bpsProjectId)
                .then(resolve, reject)
            } else {
              if (err) {
                reject(err)
              } else {
                resolve(data)
              }
            }
          })
      })
    })
  }

  function contact (projectId, request, message, action) {
    return new Promise((resolve, reject) => {
      const callback = (err, data) => {
        refreshTokenPipe(err)
          .then((reAuthenticated) => {
            if (reAuthenticated) {
              contact(projectId, request, message, action)
                .then(resolve, reject)
            } else {
              if (err) {
                reject(err)
              } else {
                resolve(data)
              }
            }
          })
      }

      if (action === 'cancel') {
        ProjectsAPI.projectsLocreqStatusUpdate(request.id, projectId, {
          state_old: request.state,
          state_new: 'cancelled',
          ...message
        }, callback)
      } else {
        ProjectsAPI.projectsLocreqContactCreate(
          request.id, projectId, message, callback
        )
      }
    })
  }

  function getFileResults (rId, pId) {
    return new Promise((resolve, reject) => {
      ProjectsAPI.projectsLocreqResultList(rId, pId, (err, data) => {
        refreshTokenPipe(err)
          .then((reAuthenticated) => {
            if (reAuthenticated) {
              getFileResults(rId, pId)
                .then(resolve, reject)
            } else {
              if (err) {
                reject(err)
              } else {
                resolve(data)
              }
            }
          })
      })
    })
  }

  function getUsers (clientId, userType) {
    return new Promise((resolve, reject) => {
      ClientsAPI.clientsUsersList(clientId, { userType }, (err, data) => {
        refreshTokenPipe(err)
          .then((reAuthenticated) => {
            if (reAuthenticated) {
              getUsers(clientId, userType)
                .then(resolve, reject)
            } else {
              if (err) {
                reject(err)
              } else {
                resolve(data)
              }
            }
          })
      })
    })
  }

  function getProjectMembers (projectId) {
    return new Promise((resolve, reject) => {
      ProjectsAPI.projectsMembersList(projectId, null, (err, data) => {
        refreshTokenPipe(err)
          .then((reAuthenticated) => {
            if (reAuthenticated) {
              getProjectMembers(projectId)
                .then(resolve, reject)
            } else {
              if (err) {
                reject(err)
              } else {
                resolve(data)
              }
            }
          })
      })
    })
  }

  function addProjectMember (projectId, user) {
    return new Promise((resolve, reject) => {
      ProjectsAPI.projectsMembersCreate(projectId, { id: user.id },
        (err) => {
        refreshTokenPipe(err)
          .then((reAuthenticated) => {
            if (reAuthenticated) {
              addProjectMember(projectId, user)
                .then(resolve, reject)
            } else {
              if (err) {
                reject(err)
              } else {
                resolve(user)
              }
            }
          })
      })
    })
  }

  function editUser (userId, data) {
    return new Promise((resolve, reject) => {
      UsersAPI.usersUpdate(userId, data, (err, user) => {
        refreshTokenPipe(err)
          .then((reAuthenticated) => {
            if (reAuthenticated) {
              editUser(userId, data)
                .then(resolve, reject)
            } else {
              if (err) {
                reject(err)
              } else {
                resolve(user)
              }
            }
          })
      })
    })
  }

  function usersUpdateClient (userId, data) {
    return new Promise((resolve, reject) => {
      UsersAPI.usersPartialUpdate(userId, data, (err, user) => {
        refreshTokenPipe(err)
          .then((reAuthenticated) => {
            if (reAuthenticated) {
              usersUpdateClient(userId, data)
                .then(resolve, reject)
            } else {
              if (err) {
                reject(err)
              } else {
                resolve(user)
              }
            }
          })
      })
    })
  }

  function deleteProjectMember (projectId, memberId) {
    return new Promise((resolve, reject) => {
      ProjectsAPI.projectsMembersDelete(memberId, projectId, (err, data) => {
        refreshTokenPipe(err)
          .then((reAuthenticated) => {
            if (reAuthenticated) {
              deleteProjectMember(projectId, memberId)
                .then(resolve, reject)
            } else {
              if (err) {
                reject(err)
              } else {
                resolve(data)
              }
            }
          })
      })
    })
  }

  function createUser (clientId, newUser) {
    return new Promise((resolve, reject) => {
      ClientsAPI.clientsUsersCreate(clientId, newUser, (err, data) => {
        refreshTokenPipe(err)
          .then((reAuthenticated) => {
            if (reAuthenticated) {
              createUser(clientId, newUser)
                .then(resolve, reject)
            } else {
              if (err) {
                reject(err)
              } else {
                resolve(data)
              }
            }
          })
      })
    })
  }

  function deleteUser (userId) {
    return new Promise((resolve, reject) => {
      UsersAPI.usersDelete(userId, (err, data) => {
        refreshTokenPipe(err)
          .then((reAuthenticated) => {
            if (reAuthenticated) {
              deleteUser(userId)
                .then(resolve, reject)
            } else {
              if (err) {
                reject(err)
              } else {
                resolve(data)
              }
            }
          })
      })
    })
  }

  function openTerms () {
    dispatch({
      type: 'OPEN_TERMS'
    })
  }

  function closeTerms () {
    dispatch({
      type: 'CLOSE_TERMS'
    })
  }

  function getClients (page, ordering) {
    return new Promise((resolve, reject) => {
      DashboardAPI.dashboardClientsList({ page, ordering }, (err, data) => {
        refreshTokenPipe(err)
          .then((reAuthenticated) => {
            if (reAuthenticated) {
              getClients(page, ordering)
                .then(resolve, reject)
            } else {
              if (err) {
                reject(err)
              } else {
                dispatch({
                  type: 'SET_CLIENTS',
                  payload: data
                })

                resolve(data)
              }
            }
          })
      })
    })
  }

  function getClientByID (id) {
    return new Promise((resolve, reject) => {
      setLoadingClient(true)
      ClientsAPI.clientsRead(id, (err, client) => {
        refreshTokenPipe(err)
          .then((reAuthenticated) => {
            if (reAuthenticated) {
              getClientByID(id)
                .then(resolve, reject)
            } else {
              setLoadingClient(false)
              if (err) {
                reject(err)
              } else {
                setCurrentClient(client)
                resolve(client)
              }
            }
          })
      })
    })
  }

  function createClient (data) {
    return new Promise((resolve, reject) => {
      ClientsAPI.clientsCreate(data, (err, client) => {
        refreshTokenPipe(err)
          .then((reAuthenticated) => {
            if (reAuthenticated) {
              createClient(data)
                .then(resolve, reject)
            } else {
              if (err) {
                reject(err)
              } else {
                dispatch({
                  type: 'ADD_CLIENT',
                  payload: client
                })
                resolve(client)
              }
            }
          })
      })
    })
  }

  function editClient (id, data) {
    return new Promise((resolve, reject) => {
      ClientsAPI.clientsUpdate(id, data, (err, client) => {
        refreshTokenPipe(err)
          .then((reAuthenticated) => {
            if (reAuthenticated) {
              editClient(id, data)
                .then(resolve, reject)
            } else {
              if (err) {
                reject(err)
              } else {
                dispatch({
                  type: 'UPDATE_CLIENT',
                  payload: client
                })
                resolve(client)
              }
            }
          })
      })
    })
  }

  function uploadLogo (file) {
    return new Promise((resolve, reject) => {
      ClientsAPI.clientsLogoRead(file.name, (err, presignedUrl) => {
        if (err) {
          reject(err)
        } else {
          // Upload file
          const fileName = presignedUrl.name

          if (fileName) {
            uploadImage(presignedUrl, file)
              .then(() => resolve(presignedUrl))
              .catch(reject)
          } else {
            reject({ response: { text: 'No filename found in presigned URL' } })
          }
        }
      })
    })
  }

  function uploadBanner (file) {
    return new Promise((resolve, reject) => {
      ClientsAPI.clientsBannerRead(file.name, (err, presignedUrl) => {
        if (err) {
          reject(err)
        } else {
          // Upload file
          const fileName = presignedUrl.name

          if (fileName) {
            uploadImage(presignedUrl, file)
              .then(() => resolve(presignedUrl))
              .catch(reject)
          } else {
            reject({ response: { text: 'No filename found in presigned URL' } })
          }
        }
      })
    })
  }

  function getBpsClients (studioId) {
    return new Promise((resolve, reject) => {
      BpsAPI.bpsClientsList({ studioId }, (err, data) => {
        refreshTokenPipe(err)
          .then((reAuthenticated) => {
            if (reAuthenticated) {
              getBpsClients(studioId)
                .then(resolve, reject)
            } else {
              if (err) {
                reject(err)
              } else {
                resolve(data)
              }
            }
          })
      })
    })
  }

  function getBpsStudios () {
    return new Promise((resolve, reject) => {
      BpsAPI.bpsStudiosList((err, data) => {
        refreshTokenPipe(err)
          .then((reAuthenticated) => {
            if (reAuthenticated) {
              getBpsStudios()
                .then(resolve, reject)
            } else {
              if (err) {
                reject(err)
              } else {
                resolve(data)
              }
            }
          })
      })
    })
  }

  function getMemoqEndpoints () {
    return new Promise((resolve, reject) => {
      MemoqAPI.memoqEndpointsList((err, data) => {
        refreshTokenPipe(err)
          .then((reAuthenticated) => {
            if (reAuthenticated) {
              getMemoqEndpoints()
                .then(resolve, reject)
            } else {
              if (err) {
                reject(err)
              } else {
                resolve(data)
              }
            }
          })
      })
    })
  }

  function generateLettersGroups () {
    dispatch({
      type: 'SET_LETTERS',
      payload: generateGroups(50)
    })
  }

  function getRequestsStats ({state, dateFrom, ...rest}) {
    return new Promise((resolve, reject) => {
      DashboardAPI.dashboardProjectsLocreqsList(
        state, dateFrom, rest, (err, data) => {
        refreshTokenPipe(err)
          .then((reAuthenticated) => {
            if (reAuthenticated) {
              getRequestsStats({ state, dateFrom, ...rest })
                .then(resolve, reject)
            } else {
              if (err) {
                reject(err)
              } else {
                resolve(data)
              }
            }
          })
      })
    })
  }

  function getRequestsTotalsStats ({state, ...rest}) {
    return new Promise((resolve, reject) => {
      DashboardAPI.dashboardProjectsLocreqsTotalList(
        state, rest, (err, data) => {
        refreshTokenPipe(err)
          .then((reAuthenticated) => {
            if (reAuthenticated) {
              getRequestsTotalsStats({ state, ...rest })
                .then(resolve, reject)
            } else {
              if (err) {
                reject(err)
              } else {
                resolve(data)
              }
            }
          })
      })
    })
  }

  function getProjectsTotalsStats (clientId) {
    return new Promise((resolve, reject) => {
      DashboardAPI.dashboardProjectsTotalList({ clientId }, (err, data) => {
        refreshTokenPipe(err)
          .then((reAuthenticated) => {
            if (reAuthenticated) {
              getProjectsTotalsStats(clientId)
                .then(resolve, reject)
            } else {
              if (err) {
                reject(err)
              } else {
                resolve(data)
              }
            }
          })
      })
    })
  }

  function getProjectsWordcount ({dateFrom, ...rest}) {
    return new Promise((resolve, reject) => {
      DashboardAPI.dashboardProjectsWordcountList(
        dateFrom, rest, (err, data) => {
        refreshTokenPipe(err)
          .then((reAuthenticated) => {
            if (reAuthenticated) {
              getProjectsWordcount({ dateFrom, ...rest })
                .then(resolve, reject)
            } else {
              if (err) {
                reject(err)
              } else {
                resolve(data)
              }
            }
          })
      })
    })
  }

  function getProjectsTotalsWordcount (opts) {
    return new Promise((resolve, reject) => {
      DashboardAPI.dashboardProjectsWordcountTotalList(opts, (err, data) => {
        refreshTokenPipe(err)
          .then((reAuthenticated) => {
            if (reAuthenticated) {
              getProjectsTotalsWordcount(opts)
                .then(resolve, reject)
            } else {
              if (err) {
                reject(err)
              } else {
                resolve(data)
              }
            }
          })
      })
    })
  }

  function getLRLanguages ({ dateFrom, ...opts }) {
    return new Promise((resolve, reject) => {
      DashboardAPI.dashboardProjectsLocreqsLanguageList(dateFrom, opts, (err, data) => {
        refreshTokenPipe(err)
          .then((reAuthenticated) => {
            if (reAuthenticated) {
              getLRLanguages({ dateFrom, ...opts })
                .then(resolve, reject)
            } else {
              if (err) {
                reject(err)
              } else {
                resolve(data)
              }
            }
          })
      })
    })
  }

  function  getFilecountChart ({ dateFrom, ...opts }) {
    return new Promise((resolve, reject) => {
      DashboardAPI.dashboardProjectsFilecountChartList(dateFrom, opts, (err, data) => {
        refreshTokenPipe(err)
          .then((reAuthenticated) => {
            if (reAuthenticated) {
              getFilecountChart({ dateFrom, ...opts })
                .then(resolve, reject)
            } else {
              if (err) {
                reject(err)
              } else {
                resolve(data)
              }
            }
          })
      })
    })
  }

  function getFilecountAvg (opts) {
    return new Promise((resolve, reject) => {
      DashboardAPI.dashboardProjectsFilecountAvgList(opts, (err, data) => {
        refreshTokenPipe(err)
          .then((reAuthenticated) => {
            if (reAuthenticated) {
              getFilecountAvg(opts)
                .then(resolve, reject)
            } else {
              if (err) {
                reject(err)
              } else {
                resolve(data)
              }
            }
          })
      })
    })
  }

  function getMemoqFilters (projectId, serviceLevel) {
    return new Promise((resolve, reject) => {
      ProjectsAPI.projectsMemoqfiltersList(projectId, { serviceLevel }, (err, data) => {
        refreshTokenPipe(err)
          .then((reAuthenticated) => {
            if (reAuthenticated) {
              getMemoqFilters(projectId, serviceLevel)
                .then(resolve, reject)
            } else {
              if (err) {
                reject(err)
              } else {
                resolve(data)
              }
            }
          })
      })
    })
  }

  function syncProject (projectId) {
    setProjectIsSyncing(true)
    return new Promise((resolve, reject) => {
      ProjectsAPI.projectsSyncBpsCreate(projectId, (err, data) => {
        refreshTokenPipe(err)
          .then((reAuthenticated) => {
            if (reAuthenticated) {
              syncProject(projectId)
                .then(resolve, reject)
            } else {
              if (err) {
                setProjectIsSyncing(false)
                reject(err)
              } else {
                resolve(parseProject(data))
              }
            }
          })
      })
    })
  }



  // MARK: - Project Syncing check
  function projectSyncingStatus(projectId) { 
    const pollingSeconds = 20
    const numberOfRetries = 7 * (60 / pollingSeconds)

    return new Promise((resolve, reject) => {
      iterableCheck(projectId, resolve, reject)
    })

    // iterate checkProjectSyncing until:
    // 1: is_syncing is returned false (from API call) or...
    // 2: too many tries (30 for now, or 5 minutes more or less)
    function iterableCheck(projectId, resolve, reject, counter = 0) {
      checkProjectSyncing(projectId, counter)
      .then(data => {
        const {is_syncing, counter} = data

        if (data && !is_syncing) {
          setProjectIsSyncing(false)
          resolve(is_syncing)
          return
        }

        if (counter > numberOfRetries) {
          setProjectIsSyncing(false)
          reject('Too many tries') // err too many tries
          return
        }

        setTimeout(() => 
          iterableCheck(projectId, resolve, reject, counter + 1), pollingSeconds * 1000)
      })
      .catch(reject)
    }

    // This is actually a complex piping of promises with self iteration 
    // It is used in the same way in every API call wrapper in this file (i think, i didn't check them all, but almost...)
    // For simplicity and disambiguity, let's call the above function the Root Promise.
    // When data and the eventual error (it could be null too...) is received from the Root Promise, the eventual error is piped to refreshTokenPipe promise, that basically check whether that error could vanish with a reauth call.
    // That promise returns true if there was a reauth or false otherwise, and attemps a reauth under the hood if there is need.
    // The Root Promise checks the result from the previous promise, and in case there wasn't a reauth, simply resolve or reject based on the eventual error of the Root Promise. 
    // If not recall the Root Promise (self iteration) and start again.

    function checkProjectSyncing(projectId, counter = 0) {
      return new Promise((resolve, reject) => {
        ProjectsAPI.projectsIsSyncingRead(projectId, (err, data) => {
          refreshTokenPipe(err).then((reAuthenticated) => {
            if (reAuthenticated) {
              checkProjectSyncing(projectId, counter)
                .then(resolve, reject)
            } else {
              if (err) {
                reject(err)
              } else {
                resolve({...data, counter})
              }
            }
          })
        })
      })
    }
  }
  // 

  function setProjectIsSyncing (payload) {
    dispatch({type: 'SET_PROJECT_IS_SYNCING', payload})
  }
  
  function setJobAssignment (projectId, token, approved) {
    const status = approved ? "approved" : "rejected"
    const postData = { token, status }

    return new Promise((resolve, reject) => {
      ProjectsAPI.projectsJobsStatusCreate(
        projectId,
        postData,
        (err, data) => {
          refreshTokenPipe(err)
            .then((reAuthenticated) => {
              if (reAuthenticated) {
                setJobAssignment(projectId, token, approved)
                  .then(resolve, reject)
              } else {
                if (err) {
                  reject(err)
                } else {
                  resolve(data)
                }
              }
            })
          })
    })
  }

  // UI ACTIONS
  function toggleRequestNew(payload) {
    dispatch({
      type: 'TOGGLE_REQUEST_NEW',
      payload
    })
  }

  function toggleProjectNew(payload) {
    const {active, clientId} = payload
    if (!active) {
      dispatch({
        type: 'TOGGLE_PROJECT_NEW',
        payload: active
      })
      return new Promise(() => {}, () => {})
    }
    
    return fetchClientActiveUsers(clientId)
    .then((activeUsers) => {
      if (activeUsers) {
        dispatch({
          type: 'TOGGLE_PROJECT_NEW',
          payload: active
        })
      }

      return activeUsers
    })
  }

  function toggleClientNew(payload) {
    dispatch({
      type: 'TOGGLE_CLIENT_NEW',
      payload
    })
  }

  function toggleProjectEdit(payload) {
    dispatch({
      type: 'TOGGLE_PROJECT_EDIT',
      payload
    })
  }

  function toggleClientEdit(payload) {
    dispatch({
      type: 'TOGGLE_CLIENT_EDIT',
      payload
    })
  }

  function toggleUserEdit(payload) {
    dispatch({
      type: 'TOGGLE_USER_EDIT',
      payload
    })
  }

  function setLoadingProject(payload) {
    dispatch({
      type: 'SET_LOADING_PROJECT',
      payload
    })
  }

  function setLoadingClient(payload) {
    dispatch({
      type: 'SET_LOADING_CLIENT',
      payload
    })
  }

  // CURRENT STATE UPDATES
  function setCurrentClient(payload) {
    dispatch({
      type: 'SET_CURRENT_CLIENT',
      payload
    })
  }

  function setCurrentProject(payload) {
    dispatch({
      type: 'SET_CURRENT_PROJECT',
      payload
    })
  }
  

  function fetchClientActiveUsers(clientId) {
    return new Promise((resolve, reject) => {
      getUsers(clientId, 'kws-pm')
      .then((data) => {
        const {activeUsersMap} = parseAssociatedClients(data.results, clientId)
        let acc = 0
        Object.keys(activeUsersMap).forEach(key => {
          acc += activeUsersMap[key]
        })
        dispatch({
          type: 'SET_CLIENT_ACTIVE_USERS_COUNT',
          payload: acc
        })
        resolve(acc)
      })
      .catch((err) => {
        console.error('catched error while getting associate kwspm users of this client', err)
        dispatch({
          type: 'SET_CLIENT_ACTIVE_USERS_COUNT',
          payload: 0
        })
        reject(err)
      })
    })
  }

  return (<GlobalContext.Provider value={{
    ...state,

    setCurrentClient, 
    setLoadingClient,
    setCurrentProject, 
    setLoadingProject,
    projectSyncingStatus,
    fetchClientActiveUsers,

    internalProjectDelete,
    toggleRequestNew,
    toggleProjectNew,
    toggleClientNew,
    toggleProjectEdit,
    toggleClientEdit,
    toggleUserEdit,
    
    // MARK: - ARCHIVED
    getArchivedProjects: getArchivedProjects(ClientsAPI, refreshTokenPipe, dispatch),
    getArchivedRequests: getArchivedRequests(ClientsAPI, refreshTokenPipe, dispatch),
    getArchivedProjectById: getArchivedProject(ClientsAPI, refreshTokenPipe, dispatch),
    archiveProject: projectsArchiveCreate(ProjectsAPI, refreshTokenPipe, dispatch),
    //


    // MARK: - HISTORY
    getLanguagesHistory: getLanguagesHistory(ProjectsAPI, refreshTokenPipe),
    //


    // MARK: - PROJECT UTILS
    checkForUnassignedFiles: checkForUnassignedFiles(ProjectsAPI, refreshTokenPipe),
    //

    // MARK: - RESOURCES
    getResources: getResources(ProjectsAPI, refreshTokenPipe),
    updateResource: updateResource(ProjectsAPI, refreshTokenPipe),

    // MARK: - USER
    getUserPicture: getUserPictureUrl(UsersAPI),
    uploadProfilePicture: uploadProfilePicture(UsersAPI),
    getUser: getUser(UsersAPI, refreshTokenPipe, getTokens, logout, getMemoqProjects, state, dispatch),
    updateUser: updateUser(UsersAPI, refreshTokenPipe),

    acceptTerms,
    authenticateUser,
    logout,
    forgotPassword,
    resetPassword,
    getUsers,
    getTokens,
    getProjects,
    getProjectByID,
    createProject,
    editProject,
    updateProject,
    deleteProject,
    removeProject,
    resetProjectsList,
    addProject,
    getRequests,
    clearRequests,
    getProjectTemplates,
    createProjectTemplate,
    editProjectTemplate,
    deleteProjectTemplate,
    getUploadUrls,
    createRequest,
    addRequest,
    updateRequestStatus,
    getRequestByID,
    editRequest,
    updateRequest,
    deleteRequest,
    getMemoqProjects,
    getBpsProjects,
    getBPSProjectLanguages,
    contact,
    getFileResults,
    getProjectMembers,
    addProjectMember,
    editUser,
    usersUpdateClient,
    deleteProjectMember,
    createUser,
    deleteUser,
    openTerms,
    closeTerms,
    getClients,
    getClientByID,
    createClient,
    editClient,
    uploadLogo,
    uploadBanner,
    getBpsClients,
    getBpsStudios,
    getMemoqEndpoints,
    generateLettersGroups,
    getRequestsStats,
    getRequestsTotalsStats,
    getProjectsTotalsStats,
    getProjectsWordcount,
    getProjectsTotalsWordcount,
    getLRLanguages,
    getFilecountChart,
    getFilecountAvg,
    getMemoqFilters,
    syncProject,
    setJobAssignment
  }}>
    {children}
  </GlobalContext.Provider> )
}

export default GlobalProvider
