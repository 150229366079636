import React, {useContext, useEffect, useState} from 'react'
import { useLocation } from 'react-router-dom'
import { NavDropdown } from 'react-bootstrap'

import { GlobalContext } from '../../../context/GlobalState'
import {disassebleRoute} from '../../../utils'

import {
    createMenuItems, superuserRoutes, clientKWSPMRoutes, clientPMRoutes
} from './logic'

import '../styles.css'


// COMPONENT
const CreateMenu = (props) => {
    const [expanded, setExpanded] = useState(false)
    const title = 'CREATE'
    const {newItems, isLoading} = props

    const activeItems = newItems.filter(item => item.isActive && item.onClick)

    // events
    const handleToggle = (value, e) => {
        if (e?.preventDefault) {
            e.preventDefault()
        }
        setExpanded(value)
    }
    const handleOutsideClick = () => {
        setExpanded(false)
    }
    const handleClick = item => (e) => {
        item.onClick(e)
        setExpanded(false)
    }
    //

    if (!activeItems.length) {
        return <NavDropdown title={title} disabled />
    }

    return <NavDropdown title={title} 
                        show={expanded}
                        onToggle={handleToggle}
                        className='headermenu'>
        {!isLoading && activeItems.map(item => 
            <NavDropdown.Item   id={item.id} 
                                className="dropdown-menu-item"
                                key={'Create-' + item.id} 
                                onBlur={handleOutsideClick}
                                onClick={handleClick(item)}>
                {item.label}
            </NavDropdown.Item>
        )}
    </NavDropdown>
}

// COMPONENT WRAPPER WITH LOGIC
const CreateMenuWrapper = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [newItems, setNewItems] = useState(createMenuItems)
    const location = useLocation()
    const {
        toggleClientNew, toggleProjectNew, toggleRequestNew, user, currentProject
    } = useContext(GlobalContext)


    const callback = items => setTimeout(() => {
        setIsLoading(false)
        setNewItems(items)
    }, 200)
   

    useEffect(() => {
        setIsLoading(true)
        const pathComponents = disassebleRoute(location.pathname)

        const pmRoutes = clientPMRoutes({
            toggleRequestNew,
            toggleProjectNew,
            toggleClientNew,
            user, currentProject
        }, callback)
    
        const kwspmRoutes = clientKWSPMRoutes({
            toggleRequestNew,
            toggleProjectNew,
            toggleClientNew,
            user, currentProject
        }, callback)
    
        const superRoutes = superuserRoutes({
            toggleRequestNew,
            toggleProjectNew,
            toggleClientNew,
            user, currentProject
        }, callback)

        if (user?.isClientPM) {
            pmRoutes(pathComponents)
            return
        }

        if (user?.isKWSPM && user?.username !== 'superuser') {
            kwspmRoutes(pathComponents)
            return
        }

        superRoutes(pathComponents)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname, currentProject])

    return <CreateMenu isLoading={isLoading} newItems={newItems} />
}

export default CreateMenuWrapper