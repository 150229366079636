import React, { useContext } from 'react'
import { Table } from 'react-bootstrap'

import { GlobalContext } from '../../../../context/GlobalState'

import ResourceRow from './resourceRow'
import { resourceRoles } from '../../../../utils'

const resourceRoleParser = value => id => ({
    id,
    can_translate: value === resourceRoles[0].value,
    can_review: value === resourceRoles[1].value
})

const ResourceTable = props => {
    const {
        project, service, resources,
        setErrorMsg, setSubmitting, setResources, 
        // setServiceIsActive, serviceIsActive
    } = props
    const { updateResource } = useContext(GlobalContext)
    // const [invalidResources, setInvalidResources] = useState([])


    if (!resources) {
        return 'Loading...'
    }

    const onRoleSelected = resource => value => {
        setErrorMsg(null)
        // return
        
        updateResource(project.id, service, resource.ids.map(resourceRoleParser(value)))
          .then((updatedResources) => {
            setResources(updatedResources)
          })
          .catch(() => {
            setErrorMsg(`${resource.memoq_username}'s role couldn't be updated`)
          })
          .finally(() => setSubmitting(false))
    }

    
    return <Table striped className="small">
        <thead>
            <tr>
                <th>MemoQ Account</th>
                <th>Full Name</th>
                <th className="w-100">Target Languages</th>
                <th>Role</th>
            </tr>
        </thead>
        <tbody>
        { resources.map(resource => <ResourceRow 
            key={resource.memoq_username} 
            resource={resource} 
            onRoleSelected={onRoleSelected(resource)} 
            />
        )}
        </tbody>
    </Table>
}

export default ResourceTable