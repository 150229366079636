import React, { useState, useContext, useEffect } from 'react'

import { useHistory } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import * as yup from "yup"
import cN from "classnames"

import { Row, Col, Form, Button, Toast, Spinner } from 'react-bootstrap'
import Dialog from 'react-bootstrap-dialog'
import Select from 'react-select'
import { toast } from 'react-toastify';

import { GlobalContext } from '../../context/GlobalState'

import { capitalize, selectStyles, selectTheme, noOptionsMessage } from '../../utils'

import { source_languages, target_languages } from '../../temp_lists'

import Loading from '../loading/Loading'
import LPTooltip from '../lp-tooltip'

const NewProject = (props) => {
  const {
    getProjects, createProject, editProject, updateProject, syncProject, internalProjectDelete, projectIsSyncing, projectSyncingStatus,
    getRequests, requests,
    user, currentClient,
    bpsProjects, bpsProjectsNotFound, getBpsProjects, getBPSProjectLanguages, getProjectTemplates, 
    toggleProjectEdit,
  } = useContext(GlobalContext)
  
  const history = useHistory()

  const [errorMsg, setErrorMsg] = useState(null)
  const [errorTitle, setErrorTitle] = useState(null)
  const [showError, setShowError] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [selectedProjectName, setSelectedProjectName] = useState(null)
  const [dialog, setDialog] = useState(null)
  const [bPSProjectLanguages, setBPSProjectLanguages] = useState([])
  const [targetLanguages, setTargetLanguages] = useState([])
  const [sourceLanguage, setSourceLanguage] = useState(source_languages?.length
    ? source_languages[0].name
    : null)
  const [loading, setLoading] = useState(false)
  const [templates, setTemplates] = useState([])

  const schema = yup.object().shape({
    bps_project_id: yup.object().shape({
      value: yup.string().required('Name is required')
    }),
    description: yup.string().required('Description is required')
  })

  const parseAPIError = (err, {
    errorTitle,
    errorFunction = '',
    onNoDetails = () => {},
    parseErrorMsg = e => e
  }) => {
    try {
      let error = JSON.parse(err.response.text)
      if (error.detail) {
        setErrorTitle(errorTitle)
        setErrorMsg(parseErrorMsg(error.detail))
        setShowError(!!error.detail)
      } else {
        onNoDetails(error)
      }
    } catch (e) {
      console.error('Error from ' + errorFunction + ':', err)
      console.error('Error parsing above error:', e)

      const msg = err.response?.text
      setErrorTitle(errorTitle)
      setErrorMsg(msg)
      setShowError(!!msg)
    }
  }

  /**
   * try {
          let error = JSON.parse(err.response.text)
          if (error.detail) {
            setErrorTitle('Project can\'t be archived')
            setErrorMsg(error.detail)
            setShowError(!!error.detail)
          } else {
            throw Error(err.response.text)
          }
        } catch (e) {
          setErrorTitle('Project can\'t be archived')
          setErrorMsg(err.response.text)
          setShowError(!!err.response.text)
        }



        try {
          let error = JSON.parse(err.response.text)
          if (error.detail) {
            setErrorTitle(errorTitle)
            setErrorMsg(capitalize(error.detail))
            setShowError(!!error.detail)
          } 
        } catch (e) {
          setErrorTitle(errorTitle)
          setErrorMsg(err.response.text)
          setShowError(err.response.text)
        }


        try {
          let error = JSON.parse(err.response?.text)
          if (error.detail) {
            errMsg = capitalize(error.detail)
          }
        } catch (e) {
          console.error('Error from syncProject:', err)
          console.error('Error parsing above error:', e)
        }
   */

  useEffect(() => {
    if (props.project) {
      setEditMode(true)
      prefillValues(props.project)
      getProjectTemplates(props.project.id)
        .then(data => setTemplates(data.results))
    }

    return () => {
      setEditMode(false)
      setShowSuccess(false)
      resetValues()
    }
    // eslint-disable-next-line
  }, [props.project])

  useEffect(() => {
    if (!bpsProjects.length && !bpsProjectsNotFound) {
      getBpsProjects(props.clientId)
    }

    // eslint-disable-next-line
  }, [bpsProjects, props.clientId])


  const { handleSubmit, control, register, errors, setError, formState, setValue, trigger, reset, clearErrors } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema)
  })

  const onCancel = (project) => {
    // Call onCancel from props
    props.onCancel(project)
  }

  const onArchiveConfirmed = () => {
    getRequests(props.project.id)
    .then(res => {
      const requestsNotInvoiced = res.results.filter(req => req.state !== 'invoiced')

      if (requestsNotInvoiced.length) {
        setErrorTitle('Project can\'t be archived')
        setErrorMsg('Warning: there are requests not yet invoiced in this project, and it cannot be archived. Please contact the Keywords PM')
        setShowError(true)
        return
      }
      onArchivePossible()
    })
    .catch(console.error)
  }

  const onArchivePossible = () => {
      props.onProjectArchived(props.project)
      .then(() => toast.success(`${props.project.name} has been archived`))
      .catch((err) => {
        parseAPIError(err, {
          errorTitle: 'Project can\'t be archived',
          errorFunction: 'onArchivePossible',
          onNoDetails: err => { throw Error(err.response.text) }
        })
      })
  }

  const onArchive = () => {
    dialog.show({
      title: `Archive ${props.project.name}`,
      body: `Do you really want to archive this project?`,
      actions: [
        Dialog.Action(
          'CANCEL',
          () => {},
          'btn-default'
        ),
        Dialog.Action(
          'YES, ARCHIVE',
          onArchiveConfirmed,
          'btn-danger'
        )
      ],
      bsSize: 'small',
    })
  }

  const onSubmit = (data) => {
    // idProject as id
    let bpsProject = bpsProjects
      .find(p => p.idProject === parseInt(data.bps_project_id.value))
    
    // name as id
    if (!bpsProject) {
      bpsProject = bpsProjects
        .find(p => {
          return p.name === data.bps_project_id.value
        })
    }


    // services
    const supportedServices = ['lite', 'platinum']
    let services = []

    supportedServices.forEach((service) => {
      const memoqItem = data['memoq_project_guid_' + service]
      if (memoqItem && memoqItem.value) {
        services.push({
          service,
          memoq_guid: memoqItem.value,
          memoq_name: memoqItem.label
        })
      }
    })

    const project = {
      description: data.description,
      bps_project_id: bpsProject.idProject,
      name: bpsProject.name,
      services,
    }

    setSubmitting(true)
    setShowError(false)

    const promise = editMode
      ? editProject(props.project.id, project)
      : createProject(props.clientId, project)

    promise
      .then((newProject) => {
        setSubmitting(false)
        reset()

        if (editMode) {
          setTimeout(() => {
            toast.success(`Project ${newProject.name} has been updated`)
          }, 10)
          onCancel(newProject)
        } else {
          props.onProjectCreated(newProject)
          setShowSuccess(true)
          document.querySelector('.fields').scrollTop = 0
          setEditMode(true)
          prefillValues(newProject)

          if (newProject.state === 'new' && user.isKWSPM) {
            setErrorTitle('Warning!')
            setErrorMsg('Until the "MemoQ projects" information is not filled, it is not possible to start the project and to have the client upload requests')
            setShowError(true)
          }
        }
      })
      .catch((err) => {
        const errorTitle = `Project ${editMode ? 'update':'creation'} failed`
        setSubmitting(false)


        parseAPIError(err, {
          errorTitle,
          errorFunction: editMode ? 'promise of edit' : 'promise of new',
          parseErrorMsg: capitalize,
          onNoDetails: error => Object.entries(error)
            .forEach(([fieldName, messages]) => {
              if (fieldName === 'name') {
                fieldName = 'bps_project_id'
              }
              if (schema.fields[fieldName] &&
                schema.fields[fieldName].fields) {
                fieldName += '.value'
              }
              setError(fieldName, {
                type: 'manual',
                message: capitalize(messages[0])
              })
            })
        })
      })
  }

  const onSync = () => {
    const {project} = props

    //
    const displayError = err => {
      let errMsg = 'Something went wrong'

      try {
        let error = JSON.parse(err.response?.text)
        if (error.detail) {
          errMsg = capitalize(error.detail)
        }
      } catch (e) {
        console.error('Error from syncProject:', err)
        console.error('Error parsing above error:', e)
      }

      toast.error(errMsg)
    }
    
    //
    syncProject(project.id)
    .then(() => {
      projectSyncingStatus(project.id)
      .then(() => {
        toast.success('The project services and languages have been updated')
        updateProject(project)
        props.onProjectSynced(project)
      })
      .catch(displayError)
    })
    .catch(displayError)
   
    
  }

  const onProjectSelected = item => {
    clearErrors('bps_project_id.value')
    // Update target languages dropdown
    if (!bPSProjectLanguages.length) {
      setLoading(true)
      getBPSProjectLanguages(props.clientId, item.value)
        .then(services => {
          if (services && services.length) {
            setBPSProjectLanguages(services)
            updateTargetLanguages(services)
          } else {
            throw new Error('No target languages for this project')
          }
        })
        .catch(() => {
          setError('bps_project_id.value', {
            type: 'manual',
            message: 'Project not supported: invalid service or language'
          })
        })
        .finally(() => setLoading(false))
    } else {
      updateTargetLanguages(bPSProjectLanguages)
    }
  }

  const prefillValues = project => {

    // set form value bps_project_id
    if (project.name) {
      const bpsProject = bpsProjects.find(p => p.name === project.name)
      const item = bpsProject
        ? {
          value: bpsProject.idProject,
          label: bpsProject.name
        }
        : {
          value: project.name,
          label: project.name
        }
      setValue('bps_project_id', item)
      setSelectedProjectName(item)
      if (bpsProject) {
        onProjectSelected(item)
      }
    }
    

    // set target_languages
    if (project.target_languages.length) {
      setTargetLanguages(target_languages
        .filter((lang) => project.target_languages.indexOf(lang.code) > -1))
    }

    // set source_language
    if (project.source_language) {
      setSourceLanguage(source_languages
        .find((lang) => lang.code === project.source_language)
        .name)
    }

    // set description
    setValue('description', project.description)

    trigger()
  }

  const updateTargetLanguages = (services) => {
    let supportedLangs = []
    services.forEach((service) => {
      if (props.project &&
        props.project.available_service_levels.indexOf(service.service) > -1) {
        supportedLangs = supportedLangs
          .concat(service.target_languages || [])
      }
    })
    setTargetLanguages(target_languages
      .filter(lang => supportedLangs.indexOf(lang.code) > -1))
  }

  const resetValues = () => {
    setSubmitting(false)
    setErrorMsg(null)
    setErrorTitle(null)
    setShowError(false)
  }

  const onConfirmedDelete = () => {
    const asyncAction = async () => {
      try {
        await internalProjectDelete(props.project.id)
        await getProjects(currentClient.id)
        toggleProjectEdit(false)
        history.push('/client/' + currentClient.id) 
      } catch(e) {
        toggleProjectEdit(false)
        setErrorTitle('Could\'t delete project')
        setErrorMsg(e.detail || 'An error occurred')
        setShowError(true)
        console.error(e)
      }
    }

    asyncAction().then(props.onProjectDeleted)
  }


  // Update button
  const UpdateButton = () => { 
    const tooltip = 'update project config from BPS and memoQ'
    const disabled = submitting || projectIsSyncing
    const label = projectIsSyncing 
      ? <Row>
        <Spinner animation="border" className='d-flex justify-content-center'
        style={{
          width: '20px', height: '20px', 
          marginLeft: '5px', marginRight: '5px', marginTop: '0px'
        }} />
        <span style={{marginRight: '5px'}}>Updating</span>
      </Row> 
      : <span>Update data</span>

    return <LPTooltip placement="top" tooltip={tooltip}>
      <Button id="sync-btn"
              variant="secondary"
              onClick={onSync}
              disabled={disabled}>
        {label}
      </Button>
    </LPTooltip>
  }


  // Delete button
  const DeleteButton = () => {
    const disabled = requests.length || templates.length || projectIsSyncing
    const tooltip  = disabled ? 'Warning: the project cannot be deleted since there is at least one LR or LR template' : 'Delete project'
    
    const onClick = () => dialog.show({
      title: `Warning`,
      body: `Are you sure you want to delete this project from OneK?`,
      actions: [
        Dialog.Action(
          'CANCEL',
          () => {},
          'btn-default'
        ),
        Dialog.Action(
          'YES',
          onConfirmedDelete,
          'btn-danger'
        )
      ],
      bsSize: 'small',
    })

    

    return <Button  id="delete-btn"
                    disabled={disabled}
                    variant="danger"
                    onClick={onClick}>
        <LPTooltip placement="top" tooltip={tooltip}>
          <span>DELETE</span>
        </LPTooltip>
    </Button>
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}
      className={cN({'has-error': showError})}>
      <Dialog ref={ (el) => setDialog(el) }/>
      <Row className="fields">
        <Col>
          <Toast show={showSuccess}
            className="success"
            onClose={() => setShowSuccess(false)}>
            <Toast.Header>
              <strong className="mr-auto">
                Project created
              </strong>
            </Toast.Header>
            <Toast.Body>
              <p>The project has been created succesfully.<br/>
              { user.isKWSPM && props.project?.state === 'ongoing' &&
                <span>{' '}The project is now ongoing and the client can upload requests.</span>
              }
              { !user.isKWSPM && props.project?.state === 'new' &&
                <span>{' '}Your reference KWS project manager is going to complete the project settings.</span>
              }
              </p>
            </Toast.Body>
          </Toast>
          <Toast show={showError}
            onClose={() => setShowError(false)}>
            <Toast.Header>
              <strong className="mr-auto">
                { errorTitle }
              </strong>
            </Toast.Header>
            <Toast.Body>{ errorMsg }</Toast.Body>
          </Toast>
          <Form.Group>
            <Form.Label>Project Name</Form.Label>
            <Controller
              id="bps_project_id"
              name="bps_project_id"
              render={({ onChange }) => (
                bpsProjectsNotFound
                  ? <p className="text-danger">Projects not found</p>
                  : selectedProjectName ||
                    (!props.project && bpsProjects.length)
                    ? <Select
                    data-dropup-auto="false"
                    onChange={(selectedItem) => {
                      onProjectSelected(selectedItem)
                      onChange(selectedItem)
                    }}
                    placeholder="Select name..."
                    styles={selectStyles}
                    theme={selectTheme}
                    className={cN('form-control', {
                      'is-invalid': errors.bps_project_id
                    })}
                    options={ bpsProjects.map((project) => {
                      return {
                        value: project.idProject,
                        label: project.name
                      }
                    }) }
                    noOptionsMessage={noOptionsMessage}
                    defaultValue={ selectedProjectName || null }
                    isDisabled={editMode && (!user.isKWSPM || props.project?.state !== 'new')}
                  />
                  : <Loading resource="projects" />
              )}
              control={control}
            />
            <Form.Control.Feedback type="invalid">
              {errors.bps_project_id?.value.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label>Description</Form.Label>
            <textarea id="description"
              name="description"
              className={cN('form-control', {
                'is-invalid': errors.description
              })}
              ref={register}
            />
            <Form.Control.Feedback type="invalid">
              {errors.description?.message}
            </Form.Control.Feedback>
          </Form.Group>

          { !!targetLanguages.length &&
            <Row>
              <Col>
                { sourceLanguage &&
                  <Form.Group>
                    <Form.Label>Source Language</Form.Label>
                    <Form.Control plaintext readOnly
                      defaultValue={sourceLanguage} />
                  </Form.Group>
                }
              </Col>
              <Col>
                { !!targetLanguages.length &&
                  <Form.Group>
                    <Form.Label>Target Languages</Form.Label>
                    <ul>
                      { targetLanguages.map((lang) => (
                        <li key={lang.code}>{lang.name}</li>
                      )) }
                    </ul>
                  </Form.Group>
                }
              </Col>
            </Row>
          }
        </Col>
      </Row>
      <Row className="actions">
        <Col xs="auto">
          <Button id="submit-btn"
            variant="primary"
            type="submit"
            disabled={submitting || projectIsSyncing || !formState.isDirty || !formState.isValid || loading}>
            { submitting ? 'Saving...' : 'SAVE PROJECT' }
          </Button>
        </Col>
        
        <Col className="text-right">
          { (user.isKWSPM && props.project) && <DeleteButton project={props.project} />}
        </Col>

        { editMode && <>
          <Col className="text-left">
            { user.isKWSPM && <UpdateButton /> }
          </Col>
          <Col className="text-left">
            <Button id="archive-btn"
              variant="primary"
              onClick={() => onArchive()}
              disabled={submitting || projectIsSyncing}>
              ARCHIVE
            </Button>
          </Col>
        </>
          
        }
      </Row>
    </Form>
  )
}

export default NewProject
