import React, { useContext, useEffect, useState } from 'react'

import { Table } from 'react-bootstrap'
import { toast } from 'react-toastify';

import { GlobalContext } from '../../../context/GlobalState'

import EntityNotFound from '../../entity-not-found/EntityNotFound'
import Loading from '../../loading/Loading'
import LPTooltip from '../../lp-tooltip'

import { parseAssociatedClients } from '../../../utils'

import './style.css'

const Contacts = (props) => {
  const { getUsers, usersUpdateClient } = useContext(GlobalContext)
  const [users, setUsers] = useState([])
  const [loadingUsers, setLoadingUsers] = useState(true)

  const [listOfActiveUsers, setListOfActiveUsers] = useState({})
  const [checkboxesDisabled, setCheckboxesDisabled] = useState(false)

  useEffect(() => {
    if (props.client) {
      setLoadingUsers(true)
      getUsers(props.client.id, 'kws-pm')
        .then((data) => {
          const {sortedUsers, activeUsersMap} = parseAssociatedClients(data.results, props.client.id)

          setUsers(sortedUsers)      
          setListOfActiveUsers(activeUsersMap)
          setLoadingUsers(false)
        })
        .catch(() => setLoadingUsers(false))
    }
    // eslint-disable-next-line
  }, [props.client])

  // 
  const handleUserActiveChange = (e, user) => {
    return new Promise((resolve, reject) => {
      setCheckboxesDisabled(true)

      const selectedUser = users.find(id => user === id)
      if (!selectedUser) {
        reject()
        return
      }
    
      // build user object with mods
      const updatedClients = computeUserClientsChanges(e, selectedUser)
      if (!updatedClients) {
        reject()
        return
      }

      const data = {client_id: updatedClients.map(id => String(id)) }
      // call API
      usersUpdateClient(user.id, data)
      .then(() => {
        const usersMap = listOfActiveUsers
        usersMap[user.id] = e
        setListOfActiveUsers(usersMap)
        resolve()
      })
      .catch(reject)
    })
    .catch(err => {
      console.error('An error has occurred: ', err)
      toast.error(`Clients are not in sync with this user. Try refreshing`)
    })
    .finally(() => setCheckboxesDisabled(false))
  }

  const computeUserClientsChanges = (e, selectedUser) => {
    const currentClientId = String(props.client.id)
    const {clients = []} = selectedUser
    const clientIndex = clients.findIndex(id => id === currentClientId)
    const clientIsActive = clientIndex > -1
    // It can happen, due to clients containing users that could not have clients associated, that there is no need to modify the clients of selectedUser

    // add
    if (e) {
      if (clientIsActive) {
        return clients
      }
      clients.push(currentClientId)
      
    // remove
    } else {
      if (!clientIsActive) {
        return clients
      }
      clients.splice(clientIndex, 1)
    }

    return clients
  }

  return (
    <>
    { loadingUsers
      ? <Loading />
      : !users.length
      ? <EntityNotFound entityName="KWS PMs" />
      : <Table striped className="small">
        <thead>
          <tr>
            <th colSpan={3}>
              KWS PMs
            </th>
          </tr>
        </thead>
        <tbody id='contact-list'>
        { users.map((u) => {
          return (
            <tr key={u.id} id='contact-list-header'>
              <td className='centered'>
                <p>{ u.name }</p>
              </td>

              <td className='centered'>
                <p>{ u.email }</p>
              </td>

              <td className='centered' id='checkbox-column'>
                <UserCheckbox checked={(listOfActiveUsers && listOfActiveUsers[u.id]) || false} handleChange={checked => handleUserActiveChange(checked, u)} disabled={checkboxesDisabled} />
              </td>
            </tr>
          )
        })}
        </tbody>
      </Table>
    }
    </>
  )
}


function UserCheckbox(props) {
  const {checked, handleChange, disabled} = props
  
  if (disabled) {
    return <CustomCheckbox checked={checked} disabled={disabled} handleChange={handleChange}
    />
  }

  const tooltipLabel = `${checked ? 'Disable' : 'Enable'} this KWS PM`
  return <LPTooltip placement='top' tooltip={tooltipLabel}>
    <div>
      <CustomCheckbox checked={checked} disabled={disabled} handleChange={handleChange}
    />
    </div>
  </LPTooltip>
}

function CustomCheckbox (props) {
  const {checked, handleChange, disabled} = props

  const sizeNum = 1
  const size = sizeNum + 'em'
  const borderThickness = (sizeNum / 7)
  const borderTop = ((sizeNum / 2) + borderThickness) + 'em'
  const borderRadius = (sizeNum / 5) + 'em'
  const color = disabled ? 'darkgrey' : (checked ? '#03c03c' : 'white')

  return <div style={{
    width: size, height: size,
    backgroundColor: color,
    border: borderThickness + 'em solid grey',
    borderRadius,
    borderTop,
    marginTop: borderTop,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  }} onClick={(e) => {
    e.preventDefault()
    if (disabled) { return }
    handleChange(!checked)
  }}> 
    {checked && <span style={{
      marginTop: (borderThickness) + 'em'
    }}>✓</span>}
  </div>
}


export default Contacts
