import React, { useState, useContext, useEffect } from 'react'

import { Modal, Row, Col, Tab, Nav } from 'react-bootstrap'
import { toast } from 'react-toastify'

import { GlobalContext } from '../../context/GlobalState'

import ProjectForm from './ProjectForm'
import Templates from './Templates'
import Members from './Members'
import ProjectContacts from './ProjectContacts'
import ServiceTab from './ServiceTab'

const NewProject = (props) => {
  const { addProject, updateProject, archiveProject, user, memoqProjects, memoqProjectsNotFound, getMemoqProjects, projectIsSyncing } = useContext(GlobalContext)
  const [editMode, setEditMode] = useState(false)
  const defaultTitle = 'Create a new project'
  const editModeTitle = 'Edit project'
  const [modalTitle, setModalTitle] = useState(defaultTitle)
  const [activeTab, setActiveTab] = useState('info')
  const [projectToEdit, setProjectToEdit] = useState(null)

  useEffect(() => {
    if (props.project) {
      setProjectToEdit(props.project)
      setEditMode(true)
      setModalTitle(editModeTitle)

      if (!memoqProjects.length && !memoqProjectsNotFound) {
        getMemoqProjects(props.clientId)
      }
    }

    return () => resetModal()
    // eslint-disable-next-line
  }, [props.project])

  const selectTab = (key) => {
    setActiveTab(key)
    if (key === 'templates') {
      setModalTitle('Project Templates')
    } else {
      if (editMode) {
        setModalTitle(editModeTitle)
      } else {
        setModalTitle(defaultTitle)
      }
    }
  }

  /** Project Events Handlers */
  const onProjectCreated = (newProject) => {
    addProject(newProject)
    setTimeout(() => {
      toast.success(`Project ${newProject.name} successfully created`)
    }, 10)
    setProjectToEdit(newProject)
    setEditMode(true)

    if (!memoqProjects.length && !memoqProjectsNotFound) {
      getMemoqProjects(props.clientId)
    }
  }

  const onProjectUpdated = (project) => {
    setProjectToEdit(project)
    updateProject(project)
    setTimeout(() => {
      toast.success(`Project ${project.name} has been updated`)
    }, 10)
  }

  const onProjectArchived = (project) => {
    archiveProject(project.id)
    resetModal()
    onHide(project, true)
  }

  const onProjectSynced = (project) => {
    setProjectToEdit(project)
    if (props.onSync) {
      props.onSync(project)
    }
  }

  const onProjectDeleted = () => {
    setEditMode(false)
    resetModal()
    onHide()
  }
  /** */

  const resetModal = () => {
    setEditMode(false)
    setModalTitle(defaultTitle)
    setProjectToEdit(null)
    setActiveTab('info')
  }

  const onHide = (project, archived) => {
    props.onHide(project || projectToEdit, archived)
    resetModal()
  }

  return (
    <Modal show={props.show} onHide={onHide} backdrop='static'
      centered scrollable size={'lg'}
      keyboard={false}
      dialogClassName="tabbed-modal">
      <Modal.Header closeButton={!projectIsSyncing}>
        <Modal.Title>
          { modalTitle }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tab.Container
          activeKey={activeTab}
          onSelect={(k) => selectTab(k)}>
          <Row>
            <Col sm="auto" className="tabs">
              <Nav className="flex-row flex-lg-column">
                <Nav.Item>
                  <Nav.Link eventKey="info">
                    PROJECT INFO
                  </Nav.Link>
                </Nav.Item>
                { user.isKWSPM &&
                  <Nav.Item>
                    <Nav.Link eventKey="lite"
                      disabled={!editMode || projectIsSyncing}>
                      LITE
                    </Nav.Link>
                  </Nav.Item>
                }
                { user.isKWSPM &&
                  <Nav.Item>
                    <Nav.Link eventKey="platinum"
                      disabled={!editMode || projectIsSyncing}>
                      PLATINUM
                    </Nav.Link>
                  </Nav.Item>
                }
                <Nav.Item>
                  <Nav.Link eventKey="templates"
                    disabled={!editMode || projectIsSyncing}>
                    TEMPLATES
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="members"
                    disabled={!editMode || projectIsSyncing}>
                    TEAM MEMBERS
                  </Nav.Link>
                </Nav.Item>
                { user.isKWSPM &&
                  <Nav.Item>
                    <Nav.Link eventKey="contacts"
                      disabled={!editMode || projectIsSyncing}>
                      KWS PMs
                    </Nav.Link>
                  </Nav.Item>
                }
              </Nav>
            </Col>
            <Col className="tab-container">
              <Tab.Content>
                <Tab.Pane eventKey="info">
                  <ProjectForm project={projectToEdit}
                    clientId={props.clientId}
                    onProjectCreated={onProjectCreated}
                    onProjectDeleted={onProjectDeleted}
                    onProjectArchived={onProjectArchived}
                    onProjectSynced={onProjectSynced}
                    onCancel={onHide} />
                </Tab.Pane>
                <Tab.Pane eventKey="lite">
                  { editMode &&
                    <ServiceTab service={"lite"}
                      onProjectUpdated={onProjectUpdated}
                      activeTab={activeTab}
                      project={projectToEdit} /> }
                </Tab.Pane>
                <Tab.Pane eventKey="platinum">
                  { editMode &&
                    <ServiceTab service={"platinum"}
                      onProjectUpdated={onProjectUpdated}
                      activeTab={activeTab}
                      project={projectToEdit} /> }
                </Tab.Pane>
                <Tab.Pane eventKey="templates">
                  { editMode &&
                    <Templates activeTab={activeTab}
                      project={projectToEdit} /> }
                </Tab.Pane>
                <Tab.Pane eventKey="members">
                  { editMode &&
                    <Members activeTab={activeTab}
                      clientId={props.clientId}
                      project={projectToEdit} /> }
                </Tab.Pane>
                <Tab.Pane eventKey="contacts">
                  { editMode &&
                    <ProjectContacts activeTab={activeTab}
                      clientId={props.clientId}
                      project={projectToEdit} /> }
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Modal.Body>
    </Modal>
  )
}

export default NewProject
