import React, { useState, useContext, useEffect } from 'react'

import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import * as yup from "yup"
import cN from "classnames"

import { Row, Col, Form } from 'react-bootstrap'
import Select from 'react-select'

import { GlobalContext } from '../../../context/GlobalState'

import { capitalize, selectStyles, selectTheme, noOptionsMessage } from '../../../utils'

import Loading from '../../loading/Loading'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ResourcesSection from './resources'


const ServiceTab = props => {
  const { memoqProjects, memoqProjectsNotFound, editProject, getResources } = useContext(GlobalContext)
  const [selectedProject, setSelectedProject] = useState(null)
  const [resources, setResources] = useState(null)
  const [resourcesNotFound, setResourcesNotFound] = useState(false)
  const [serviceIsActive, setServiceIsActive] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  

  const schema = yup.object().shape({
    memoq_project_guid_lite: yup.object().shape({
      value: yup.string().nullable()
    })
  })

  useEffect(() => {

    if (props.project && memoqProjects) {
      prefillValues(props.project)
    }

    if (props.project && props.service && !resources) {
      fetchResources()
    }

    // eslint-disable-next-line
  }, [props.project, memoqProjects])

  const { control, errors, setValue, trigger, setError } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema)
  })

  const onSubmit = (selectedItem) => {
    setSubmitting(true)
    editProject(props.project.id, {
      bps_project_id: props.project.bps_project_id,
      name: props.project.name,
      description: props.project.description,
      services: [{
        service: props.service,
        memoq_guid: selectedItem.value,
        memoq_name: selectedItem.label
      }]
    })
      .then(project => {
        setSelectedProject(selectedItem)
        fetchResources()
        props.onProjectUpdated(project)
      })
      .catch(serviceErrorHandler)
      // If service can't be found or is not set properly, display error
      /*
      .catch((e) => {
        let message 
        if (e.message === "Conflict")
        { message = "The selected project has already been chosen; it is not possilbe to select a memoQ project twice!"}
        else 
        { message = 'This service is either missing or not configured properly'}
        setError(`memoq_project_guid_${props.service}.value`, {
          type: 'manual',
          message
        })
      })
      /** */
      .finally(() => setSubmitting(false))
  }

  const prefillValues = project => {
    if (project.services && project.services.length) {

      // set active services
      const actives = {}

      project.services.forEach(function (service) {

        actives[service.service] = service.is_active

        if (service.service === props.service
          && memoqProjects.length) {
          const item = {
            // is_active: service.is_active,
            value: service.memoq_guid,
            label: service.memoq_name,
          }
          setValue('memoq_project_guid_' + service.service, item)
          setSelectedProject(item)
          setServiceIsActive(service.is_active)
        }
      })
    }

    trigger()
  }

  const fetchResources = () => {
    getResources(props.project.id, props.service)
      .then((resources) => {
        setResources(resources)
      })
      .catch(() => {
        setServiceIsActive(false)
        setResourcesNotFound(true)
      })
  }

  const serviceErrorHandler = errorResponse => {
    const defaultMessage = 'This service is either missing or not configured properly'

    const {status, body} = errorResponse || {}
    const message = status === 409 ? body : defaultMessage

    setError(`memoq_project_guid_${props.service}.value`, {
      type: 'manual',
      message
    })
  }


  return (<>
    <h1>
      Translation { capitalize(props.service) } Service
      <button className="icon-only" color="teal"> <FontAwesomeIcon  color={serviceIsActive ? "Chartreuse"  : "Crimson"} icon={serviceIsActive ? "check" : "times"}  /> </button>
    </h1>

    <Row className="fields">
      <Col>
      
        {/* PROJECT SERVICE SECTION */}
        <Form className="h-auto">
          <Form.Group>
            <Form.Label>
              MemoQ Project
            </Form.Label>
            <Controller
              id={ `memoq_project_guid_${props.service}` }
              name={ `memoq_project_guid_${props.service}` }
              render={({ onChange }) => (
                memoqProjectsNotFound
                  ? <p className="text-danger">Memoq Projects not found</p>
                  : selectedProject ||
                    (!props.project?.services?.find((s) => s.service === props.service) && memoqProjects.length)
                    ? <Select
                    data-dropup-auto="false"
                    onChange={(selectedItem) => {
                      onChange(selectedItem)
                      onSubmit(selectedItem)
                    }}
                    placeholder="Select project..."
                    styles={selectStyles}
                    theme={selectTheme}
                    className={cN('form-control', {
                      'is-invalid': errors[`memoq_project_guid_${props.service}`]
                    })}
                    options={ memoqProjects.map((project) => {
                      return {
                        value: project.guid,
                        label: project.name
                      }
                    }) }
                    noOptionsMessage={noOptionsMessage}
                    defaultValue={ selectedProject || null }
                    isDisabled={submitting || (selectedProject &&
                      props.project?.services?.find((s) => s.service === props.service))}
                  />
                  : <Loading resource="MemoQ projects" />
              )}
              control={control}
            />
            <Form.Control.Feedback type="invalid">
              {errors[`memoq_project_guid_${props.service}`]?.value?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
        {/* PROJECT SERVICE SECTION */}


        {/* RESOURCES SECTION */}
        <ResourcesSection resources={resources} 
                          resourcesNotFound={resourcesNotFound} 
                          setResources={setResources} 
                          setSubmitting={setSubmitting}
                          serviceIsActive={serviceIsActive}
                          setServiceIsActive={setServiceIsActive}
                          project={props.project} 
                          service={props.service} />
      </Col>
    </Row>
  </>)
}

export default ServiceTab
