import React, { useState, useContext, useEffect } from 'react'

import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import * as yup from "yup"
import cN from "classnames"

import { Row, Col, Form, Button, Toast } from 'react-bootstrap'
import Select from 'react-select'

import { GlobalContext } from '../../context/GlobalState'

import { capitalize, selectStyles, selectTheme, noOptionsMessage } from '../../utils'

import { services, source_languages, target_languages } from '../../temp_lists'

import TargetLanguageSection from '../new-request/TargetLanguagesSection'

const TemplateForm = (props) => {
  const { createProjectTemplate, editProjectTemplate } = useContext(GlobalContext)
  const [errorMsg, setErrorMsg] = useState(null)
  const [showError, setShowError] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [projectLanguages, setProjectLanguages] = useState(target_languages)
  const [editMode, setEditMode] = useState(false)
  const [selectedService, setSelectedService] = useState(null)

  const schema = yup.object().shape({
    name: yup.string().trim()
      .required('Name is required')
      .max(100, 'Name must be at most 100 characters'),
    service: yup.object().shape({
      value: yup.string().required('Service is required')
    }),
    source_language: yup.object().shape({
      value: yup.string().required('Source Language is required')
    }),
    target_languages: yup.array()
      .required('Select at least one Target Language')
      .ensure('Select at least one Target Language')
  })

  useEffect(() => {
    if (props.project &&
      props.project.target_languages) {
      setProjectLanguages(target_languages.filter((l) => {
        return props.project.target_languages.indexOf(l.code) > -1
      }))
    }
    return () => {
      setProjectLanguages(target_languages)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (props.template) {
      setEditMode(true)
      prefillValues(props.template)
    }

    return () => {
      setEditMode(false)
      resetValues()
    }
    // eslint-disable-next-line
  }, [props.template])


  const { handleSubmit, control, register, errors, setError, formState, setValue, trigger, getValues } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema)
  })

  const onHide = (newTemplate) => {
    // Reset default values
    setProjectLanguages(target_languages)

    // Call onHide from props
    props.onHide(newTemplate)
  }

  const onSubmit = (data) => {
    // Parse values from dropdown's
    data = {
      ...data,
      service: data.service.value,
      source_language: data.source_language.value,
      target_languages: data.target_languages.map((item) => {
        return item.value
      })
    }

    setSubmitting(true)
    setShowError(false)

    const promise = editMode
      ? editProjectTemplate(props.project.id, props.template.id, data)
      : createProjectTemplate(props.project.id, data)

    promise
      .then(onHide)
      .catch((err) => {
        setSubmitting(false)
        let errorMessage = ''
        try {
          let error = JSON.parse(err.response.text)
          if (error.detail) {
            errorMessage = error.detail
          } else {
            Object.entries(error)
              .forEach(([fieldName, messages]) => {
                if (schema.fields[fieldName] &&
                  schema.fields[fieldName].fields) {
                  fieldName += '.value'
                }
                setError(fieldName, {
                  type: 'manual',
                  message: capitalize(messages[0])
                })
              })
          }
        } catch (e) {
          errorMessage = err.response.text
        }
        setErrorMsg(errorMessage)
        setShowError(!!errorMessage)
      })
  }

  const prefillValues = template => {
    setValue('name', template.name)

    if (template.service) {
      const service = services
        .find((service) => service.code === template.service)
      const serviceItem = {
        value: service.code,
        label: service.name
      }
      setValue('service', serviceItem)
      onServiceSelected(serviceItem, true)
    }

    if (template.source_language) {
      const sourceLanguage = source_languages
        .find((lang) => lang.code === template.source_language)
      setValue('source_language', {
        value: sourceLanguage.code,
        label: sourceLanguage.name
      })
    }

    if (template.target_languages) {
      const targetLanguages = target_languages
        .filter((lang) => {
          return template.target_languages.indexOf(lang.code) > -1
        })
        .map ((lang) => {
          return {
            value: lang.code,
            label: lang.name
          }
        })
      setValue('target_languages', targetLanguages)
    }

    trigger()
  }

  const onServiceSelected = (item, dontFilterOut) => {
    const projLangs = props.project.target_languages_per_service[item.value]
    const currentTargetLangs = getValues('target_languages')

    setSelectedService(item.value)
    setProjectLanguages(projLangs)

    if (!dontFilterOut &&
      currentTargetLangs && currentTargetLangs.length) {
      setValue('target_languages', currentTargetLangs
        .filter((i) => projLangs.some((l) => l.code === i.value)))
    }
  }

  const resetValues = () => {
    setSubmitting(false)
    setErrorMsg(null)
    setShowError(false)
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Toast show={showError}
        onClose={() => setShowError(false)}>
        <Toast.Header>
          <strong className="mr-auto">
            Template {editMode ? 'update':'creation'} failed
          </strong>
        </Toast.Header>
        <Toast.Body>{ errorMsg }</Toast.Body>
      </Toast>
      <Row className="fields">
        <Col>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <input id="name"
              name="name"
              type="text"
              className={cN('form-control', {
                'is-invalid': errors.name
              })}
              ref={register}
            />
            <Form.Control.Feedback type="invalid">
              {errors.name?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Service</Form.Label>
            <Controller
              render={
                (fieldProps) => (
                  <Select
                    data-dropup-auto="false"
                    onChange={(item) => {
                      fieldProps.onChange(item);
                      onServiceSelected(item)
                    }}
                    placeholder="Select service..."
                    styles={selectStyles}
                    theme={selectTheme}
                    className={cN('form-control', {
                      'is-invalid': errors.service
                    })}
                    options={ services
                      .filter((service) => {
                        return !props.project ||
                          !props.project.available_service_levels ||
                          props.project.available_service_levels
                            .indexOf(service.code) > -1
                      })
                      .map((service) => {
                        return {
                          value: service.code,
                          label: service.name
                        }
                      }) }
                    noOptionsMessage={noOptionsMessage}
                    value={fieldProps.value}/>
                )
              }
              id="service"
              name="service"
              control={control}
            />
            <Form.Control.Feedback type="invalid">
              {errors.service?.value.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Source Language</Form.Label>
            <Controller as={Select}
              id="source_language"
              name="source_language"
              control={control}
              placeholder="Select language..."
              styles={selectStyles}
              theme={selectTheme}
              className={cN('form-control', {
                'is-invalid': errors.source_language
              })}
              options={ source_languages.map((lang) => {
                return {
                  value: lang.code,
                  label: lang.name
                }
              }) }
              noOptionsMessage={noOptionsMessage}
            />
            <Form.Control.Feedback type="invalid">
              {errors.source_language?.value.message}
            </Form.Control.Feedback>
          </Form.Group>

          <TargetLanguageSection 
            control={control}
            languages={projectLanguages}
            errors={errors}
            isDisabled={!selectedService}
          />

        </Col>
      </Row>
      <Row>
        <Col>
          <Button id="submit-btn"
            variant="primary"
            type="submit"
            disabled={submitting || !formState.isDirty || !formState.isValid  }>
            { submitting ? 'Saving...' : 'SAVE TEMPLATE' }
          </Button>
          <Button id="cancel-btn"
            variant="default"
            onClick={() => onHide()}
            disabled={submitting}>
            CANCEL
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default TemplateForm
