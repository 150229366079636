import React, { useReducer, useContext, useState } from 'react'
import '../style.scss'

import Dropzone  from 'react-dropzone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GlobalContext } from '../../../context/GlobalState'

import { Form } from 'react-bootstrap'
import { useEffect } from 'react'

import supportedTypes from '../supportedTypes'
import {filterValidFiles, getIcon, uploadedUrls, sizeLabel} from './utils'
import reducer from '../reducer'

import SectionHeader from './sections/header'
import FileOptionsError from './sections/errors'
import FileTable from './sections/files'


const GroupedFilesUploader = (props) => {
  const {
    fileOptions,
    onFileUploaded = () => {}, onFileRemoved = () => {},
    projectId,
    label
  } = props

  const [files, dispatch] = useReducer(reducer, {})
  const {getUploadUrls} = useContext(GlobalContext)
  const [ignoredFiles, setIgnoredFiles] = useState([])
  const [rejectedFiles, setRejectedFiles] = useState([])

  useEffect(() => {
    if (!Object.keys((files || {})).length) {
      return
    }

    Object.keys(files)
    .forEach(name => dispatch({
      type: 'add_existent_file',
      payload: files[name]
    }))
  }, [files])


  // events
  const onFilesDrop = droppedFiles =>  {
    const {validFiles, rejectedFiles, ignoredFiles} = filterValidFiles(files, droppedFiles, fileOptions)

    setIgnoredFiles(ignoredFiles)
    setRejectedFiles(rejectedFiles)

    const mappedFiles = validFiles.reduce((files, file) => {
      files[file.name] = {
        name: file.name,
        icon: <FontAwesomeIcon icon={getIcon(file)} />,
        size: sizeLabel(file.size),
        originalFile: file,
        uploading: false,
        progress: 0
      }
      return files
    }, {})

    if (validFiles.length) {
      dispatch({
        type: 'add_files',
        payload: mappedFiles
      })
      
      uploadFiles(validFiles, mappedFiles)
    }
  }

  const uploadFiles = (validFiles, mappedFiles) => {
    const onError = (payload) => dispatch({
      type: 'upload_failed',
      payload
    })

    const onProgress = (progress, name) => dispatch({
      type: 'update_progress',
      payload: {
        name,
        progress
      }
    })

    const onCompleted = (file, name, presignedUrl) => {
      dispatch({
        type: 'upload_completed',
        payload: {
          name
        }
      })
      onFileUploaded({
        ...presignedUrl,
        format: file.type,
        size: file.size
      })
    }

    getUploadUrls(projectId, 
      validFiles.map(file => file.name))
      .then((presignedUrls) => uploadedUrls(presignedUrls, mappedFiles, onProgress, onCompleted, onError) )
      .catch(() => validFiles.forEach((file) => onError(file.name)))
  }

  const onDeleteFile = (e, file) => {
    e.preventDefault()
    onFileRemoved(file.name, file.id)
    
    dispatch({
      type: 'delete_file',
      payload: {
        name: file.name
      }
    })
  }

  const dropzoneAccepts = supportedTypes
    .filter(t => 
      Object.keys(fileOptions).indexOf(t.extension.substr(1)) > -1)
    .map(t => t.type)

  return <Form.Group>
    <Form.Label>{ label }</Form.Label>
    <Dropzone accept={dropzoneAccepts} 
              onDrop={onFilesDrop}
    >
      {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, fileRejections }) => 
        <div>
          <SectionHeader 
            getRootProps={getRootProps} 
            getInputProps={getInputProps} 
            isDragActive={isDragActive} 
            isDragAccept={isDragAccept} 
            isDragReject={isDragReject}
            fileOptions={fileOptions} 
          />

          <FileOptionsError 
            ignoredFiles={ignoredFiles} 
            rejectedFiles={rejectedFiles}
            fileOptions={fileOptions} 
          />

          <FileTable 
            files={files} 
            onDeleteFile={onDeleteFile}
          />
        </div>
      }
    </Dropzone>
  </Form.Group>
}

export default GroupedFilesUploader
