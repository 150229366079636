import React from 'react'

import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import TargetLanguages from '../../../target-languages/TargetLanguages'

import ResourceRoles from './roles'


//
const ResourceRow = (props) => {
    const {resource, onRoleSelected, isValid} = props

    const {
        memoq_username, 
        name, 
        target_languages
    } = (resource || {})
    const styling = isValid ? {cursor: 'default'} : { color: 'red', cursor: 'default' }

    return <>
        <td style={styling}>{memoq_username}</td>
        <td style={styling}>{name}</td>
        <td style={styling}>
            <TargetLanguages langs={target_languages} />
        </td>
        <td>
            <ResourceRoles resource={resource} onRoleSelected={onRoleSelected} />
        </td>
    </>
}


//
const ResourceRowWrapper = (props) => {
    const {resource, onRoleSelected, notValid} = props

    const {
        classification_check_passed, 
        language_check_passed, 
    } = (resource || {})

    const isValid = language_check_passed && classification_check_passed && !notValid

    const tooltipMessage = notValid 
        ? 'This language is not correctly configured'
        : classification_check_passed 
            ? 'Warning: the target languages for this resource do not match.' 
            : 'The list of resources for this target language is not correct, please select freelancers or an agency only'

    return isValid 
        ? <tr>
            <ResourceRow    resource={resource} 
                            onRoleSelected={onRoleSelected} 
                            isValid={isValid} />
        </tr>
        : <OverlayTrigger placement="top" overlay={
            <Tooltip>
                {tooltipMessage}
            </Tooltip>
        }>
            <tr>
                <ResourceRow    resource={resource} 
                                onRoleSelected={onRoleSelected} 
                                isValid={isValid} />
            </tr>
        </OverlayTrigger> 
}

export default ResourceRowWrapper