import React, { useState } from 'react'

import { Toast } from 'react-bootstrap'
import EntityNotFound from '../../../entity-not-found/EntityNotFound'

import ResourceTable from './resourceTable'


const ResourcesSection = props => {
    const [errorMsg, setErrorMsg] = useState(null)
    const {
        resources, setResources, resourcesNotFound, 
        project, service, setSubmitting, 
        setServiceIsActive, serviceIsActive
    } = props

    return <>
        <h2 className="mt-2">Resources</h2>
        { errorMsg &&
        <Toast>
            <Toast.Header closeButton={false}>
            <strong className="mr-auto">Something went wrong</strong>
            </Toast.Header>
            <Toast.Body>{ errorMsg }</Toast.Body>
        </Toast>
        }

        <ResourceTable  resources={resources} 
                        project={project} service={service}
                        setErrorMsg={setErrorMsg} 
                        setSubmitting={setSubmitting} 
                        setResources={setResources}
                        serviceIsActive={serviceIsActive}
                        setServiceIsActive={setServiceIsActive}
        />

        { resourcesNotFound && <EntityNotFound entityName="Resources" /> }
    </>
}

export default ResourcesSection