import React, { useState, useContext, useEffect } from 'react'
import './client-form.scss'

import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import * as yup from "yup"
import cN from "classnames"

import { Form, Button, Toast, Image, Row, Col, Spinner } from 'react-bootstrap'
import Select from 'react-select'

import { GlobalContext } from '../../context/GlobalState'

import UploadFileBtn from '../UploadFileBtn'

import { capitalize, selectStyles, selectTheme, noOptionsMessage } from '../../utils'

import Loading from '../loading/Loading'

const ClientForm = (props) => {
  const { createClient, editClient, user, uploadLogo, uploadBanner, getBpsClients, getBpsStudios, getMemoqEndpoints } = useContext(GlobalContext)
  const [errorMsg, setErrorMsg] = useState(null)
  const [showError, setShowError] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [logo, setLogo] = useState(null)
  const [banner, setBanner] = useState(null)
  const [uploadingLogo, setUploadingLogo] = useState(false)
  const [uploadingBanner, setUploadingBanner] = useState(false)
  const [imagesToUpdate, setImagesToUpdate] = useState({})
  const [selectedClientName, setSelectedClientName] = useState(null)
  const [selectedStudio, setSelectedStudio] = useState(null)
  const [selectedMemoqEndpoint, setSelectedMemoqEndpoint] = useState(null)
  const [bpsClients, setBpsClients] = useState([])
  const [loadingClients, setLoadingClients] = useState(false)
  const [bpsStudios, setBpsStudios] = useState([])
  const [memoqEndpoints, setMemoqEndpoints] = useState([])

  const schema = yup.object().shape(user.isKWSPM && !props.client
    ? {
      studio: yup.object().shape({
        value: yup.string().required('Studio is required')
      }),
      bps_client_id: yup.object().shape({
        value: yup.string().required('Client Name is required')
      }),
      memoq_endpoint: yup.object().shape({
        value: yup.string().required('MemoQ Endpoint is required')
      })
    }
    : {})
  const formInit = {
    mode: 'onChange',
    resolver: yupResolver(schema)
  }

  if (props.client) {
    formInit.defaultValues = {
      name: props.client.name,
      studio: props.client.studio
    }
  }

  useEffect(() => {
    if (props.client) {
      setEditMode(true)
      setLogo(props.client.logo)
      setBanner(props.client.banner)
    }

    return () => setEditMode(false)
  }, [props.client])

  useEffect(() => {
    if (user.isKWSPM) {
      getBpsStudios()
        .then((bpsStudios) => {
          setBpsStudios(bpsStudios)
          if (props.client) {
            const studio = bpsStudios
              .find((studio) => studio.id === props.client.studio)
            const item = studio
              ? {
                value: studio.id,
                label: studio.label
              }
              : {
                value: props.client.studio,
                label: props.client.studio
              }
            onStudioSelected(item, props.client.bps_client_id)
          }
        })

      getMemoqEndpoints()
        .then((memoqEndpoints) => {
          setMemoqEndpoints(memoqEndpoints)

          if (props.client) {
            if (memoqEndpoints.length) {
              const endpoint = memoqEndpoints
                .find((endpoint) => endpoint === props.client.memoq_endpoint)

              if (endpoint) {
                setSelectedMemoqEndpoint({
                  value: endpoint,
                  label: endpoint
                })
              }
            }
          }
        })
    }
    // eslint-disable-next-line
  }, [props.client])

  const { handleSubmit, setError, formState, reset, errors, control, clearErrors, setValue } = useForm(formInit)

  const onFileSelected = (image, target) =>  {
    if (image) {
      setShowError(false)
      setErrorMsg(null)

      const promise = target === 'banner'
        ? uploadBanner(image)
        : uploadLogo(image)

      if (target === 'banner') {
        setUploadingBanner(true)
      } else {
        setUploadingLogo(true)
      }
      promise
        .then((presignedUrl) => {

          const fr = new FileReader();
          fr.onload = function () {
            if (target === 'banner') {
              setBanner(fr.result)
              setImagesToUpdate({
                ...imagesToUpdate,
                banner: presignedUrl
              })
              setUploadingBanner(false)
            } else {
              setLogo(fr.result)
              setImagesToUpdate({
                ...imagesToUpdate,
                logo: presignedUrl
              })
              setUploadingLogo(false)
            }
          }
          fr.readAsDataURL(image)
        })
        .catch(onError)
    }
  }

  const onError = (err) => {
    setSubmitting(false)
    setUploadingBanner(false)
    setUploadingLogo(false)
    try {
      let error = JSON.parse(err.response.text)
      if (error.detail) {
        setErrorMsg(error.detail)
        setShowError(!!error.detail)
      } else {
        Object.entries(error)
          .forEach(([fieldName, messages]) => {
            if (fieldName === 'name') {
              fieldName = 'bps_client_id'
            }
            if (schema.fields[fieldName] &&
              schema.fields[fieldName].fields) {
              fieldName += '.value'
            }
            setError(fieldName, {
              type: 'manual',
              message: capitalize(messages[0])
            })
          })
      }
    } catch (e) {
      setErrorMsg(err.response
        ? err.response.text
        : 'Something went wrong.')
      setShowError(true)
    }
  }

  const onClientSelected = (client) => {
    setSelectedClientName(client)
    clearErrors('bps_client_id.value')
  }

  const onMemoqEndpointSelected = (endpoint) => {
    setSelectedMemoqEndpoint(endpoint)
  }

  const onStudioSelected = (studio, currentClientId) => {
    // Reset clients-related state vars
    setBpsClients([])
    setSelectedClientName(null)
    setValue('bps_client_id', null)
    clearErrors('bps_client_id.value')
    clearErrors('studio.value')

    // Update bps clients list
    setSelectedStudio(studio)
    setLoadingClients(true)
    getBpsClients(studio.value)
      .then((bpsClients) => {
        setLoadingClients(false)
        setBpsClients(bpsClients)

        if (currentClientId) {
          const client = bpsClients
            .find((client) => client.id === currentClientId)

          setSelectedClientName(client
            ? {
              value: client.id,
              label: client.companyName
            }
            : {
              value: currentClientId,
              label: currentClientId
            })
        }
      }, () => setLoadingClients(false))
  }

  const onSubmit = (data) => {
    setSubmitting(true)
    setShowError(false)
    setErrorMsg(null)

    data = {
      ...data,
      ...imagesToUpdate
    }

    if (!editMode) {
      data.bps_client_id = parseInt(data.bps_client_id.value)
      data.studio = data.studio.value
      data.memoq_endpoint = data.memoq_endpoint.value
      data.name = bpsClients
        .find((client) => client.id === data.bps_client_id)
        .companyName
    }

    const promise = editMode
      ? editClient(props.client.id, data)
      : createClient(data)

    promise
      .then((client) => {
        props.onSubmit(client)
        setImagesToUpdate({})
        reset()
        setSubmitting(false)
      })
      .catch(onError)
  }

  const canClickOnSubmit = () => {
    return !(submitting ||
      (!formState.isDirty && !Object.keys(imagesToUpdate).length) ||
      !formState.isValid ||
      uploadingLogo ||
      uploadingBanner ||
      loadingClients ||
      !bpsClients.length)
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Toast show={showError}
        onClose={() => setShowError(false)}>
        <Toast.Header>
          <strong className="mr-auto">
            Client { editMode ? 'update':'creation'} failed
          </strong>
        </Toast.Header>
        <Toast.Body>{ errorMsg }</Toast.Body>
      </Toast>
      <Row className="fields">
        <Col>
          <Form.Group>
            <Form.Label>Reference Studio</Form.Label>
            <Controller
              id="studio"
              name="studio"
              render={({ onChange }) => (
                selectedStudio ||
                  (!props.client && bpsStudios.length)
                  ? <Select
                  onChange={(selectedItem) => {
                    onStudioSelected(selectedItem)
                    onChange(selectedItem)
                  }}
                  placeholder="Select studio..."
                  styles={selectStyles}
                  theme={selectTheme}
                  className={cN('form-control', {
                    'is-invalid': errors.studio
                  })}
                  options={ bpsStudios.map((studio) => {
                    return {
                      value: studio.id,
                      label: studio.label
                    }
                  }) }
                  noOptionsMessage={noOptionsMessage}
                  defaultValue={ selectedStudio || null }
                  isDisabled={editMode}
                />
                : <Loading resource="studios" />
              )}
              control={control}
            />
            <Form.Control.Feedback type="invalid">
              {errors.studio?.value.message}
            </Form.Control.Feedback>
          </Form.Group>
          { selectedStudio &&
            <Form.Group>
              <Form.Label>Client Name</Form.Label>
              <Controller
                id="bps_client_id"
                name="bps_client_id"
                render={({ onChange }) => (
                  selectedClientName ||
                    (!props.client && bpsClients.length)
                    ? <Select
                    onChange={(selectedItem) => {
                      onClientSelected(selectedItem)
                      onChange(selectedItem)
                    }}
                    placeholder="Select name..."
                    styles={selectStyles}
                    theme={selectTheme}
                    className={cN('form-control', {
                      'is-invalid': errors.bps_client_id
                    })}
                    options={ bpsClients.map((client) => {
                      return {
                        value: client.id,
                        label: client.companyName
                      }
                    }) }
                    noOptionsMessage={noOptionsMessage}
                    defaultValue={ selectedClientName || null }
                    isDisabled={editMode}
                  />
                  : loadingClients
                    ? <Loading resource="clients" />
                    : <p className="text-danger">
                        Clients not found for this studio
                      </p>
                )}
                control={control}
              />
              <Form.Control.Feedback type="invalid">
                {errors.bps_client_id?.value.message}
              </Form.Control.Feedback>
            </Form.Group>
          }
          <Form.Group>
            <Form.Label>MemoQ Endpoint</Form.Label>
            <Controller
              id="memoq_endpoint"
              name="memoq_endpoint"
              render={({ onChange }) => (
                selectedMemoqEndpoint ||
                  (!props.client && memoqEndpoints.length)
                  ? <Select
                  onChange={(selectedItem) => {
                    onMemoqEndpointSelected(selectedItem)
                    onChange(selectedItem)
                  }}
                  placeholder="Select endpoint..."
                  styles={selectStyles}
                  theme={selectTheme}
                  className={cN('form-control', {
                    'is-invalid': errors.memoq_endpoint
                  })}
                  options={ memoqEndpoints.map((endpoint) => {
                    return {
                      value: endpoint,
                      label: endpoint
                    }
                  }) }
                  noOptionsMessage={noOptionsMessage}
                  defaultValue={ selectedMemoqEndpoint || null }
                  isDisabled={editMode}
                />
                : <Loading resource="endpoints" />
              )}
              control={control}
            />
            <Form.Control.Feedback type="invalid">
              {errors.memoq_endpoint?.value.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            { logo &&
              <Image src={ logo }
                alt="Client logo" className="client-image" fluid/>
            }
            <UploadFileBtn accept="image/*" maxSize={2}
              uploading={uploadingLogo}
              onFilesDrop={(image) => onFileSelected(image, 'logo')}>
              Upload Logo
            </UploadFileBtn>
          </Form.Group>
          <Form.Group>
            { banner &&
              <Image src={ banner }
                alt="Client banner" className="client-image" fluid/>
            }
            <UploadFileBtn accept="image/*" maxSize={5}
              uploading={uploadingBanner}
              onFilesDrop={(image) => onFileSelected(image, 'banner')}>
              Upload Banner
            </UploadFileBtn>
          </Form.Group>
        </Col>
      </Row>
      <Row className="actions">
        <Col className="text-center">
          <Button id="submit-btn"
            variant="primary"
            type="submit"
            disabled={ !canClickOnSubmit() }>
            { submitting
              ? <Row>
                <Spinner  animation='border' 
                          className='d-flex justify-content-center'
                          style={{
                            width: '20px', height: '20px', 
                            marginLeft: '5px', marginRight: '5px', marginTop: '0px'
                          }} 
                />
                <span style={{ marginRight: '5px' }}>Submitting...</span>
              </Row>

              : (loadingClients || !bpsClients.length) 
                ? <Row>
                <Spinner  animation='border' 
                          className='d-flex justify-content-center'
                          style={{
                            width: '20px', height: '20px', 
                            marginLeft: '5px', marginRight: '5px', marginTop: '0px'
                          }} 
                />
                <span style={{ marginRight: '5px' }}>{editMode
                  ? 'SAVE CLIENT'
                  : 'CREATE CLIENT'}</span>
              </Row>
                : editMode
                  ? 'SAVE CLIENT'
                  : 'CREATE CLIENT'
            }
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default ClientForm
